"use client"

import * as React from "react"
import "../CoolTable.styles.css"
import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import { DataTablePagination } from "./data-table-pagination"
import { DataTableToolbar } from "./data-table-toolbar"
import { getColumns } from "./columns"
import { GenericObject } from "@/models/UtilsModel"
import { Badge } from "@/components/ui/badge"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useRouter } from "next/router"
import { DataCards } from "./data-cards"
import { DataTableSheet, dataTableSheetModel, setDetailIsOpen } from "@/components/orchestrators/cool-table/components/data-table-sheet"
import { CircleCheckIcon, CircleXIcon, LoaderCircleIcon } from "lucide-react"
import { DataTableRowActions } from "./data-table-row-actions"
import { DataProductCards, getTableScrollAreaRef } from "./data-product-cards"
import useSWR from "swr"
import { getFilterCategoryById } from "@/services/api/category.service"
import { setAV, setBF, setGF, setSBF, setSelectedF, setSF, setSGF, setSSF } from "@/components/presentational/FilterAccordion"
import { setgid } from "process"
import { cn } from "@/lib/utils"
import { useVirtualizer } from '@tanstack/react-virtual'
import { get } from "http"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable"
import { DataTableDetail } from "./data-table-detail"
import { set } from "zod"
import { comma } from "postcss/lib/list"
import { getPanelGroupElement } from "react-resizable-panels"
import LoadingPanel from "@/components/loading-panel"

interface DataTableProps<TData> {
    data: any,
    columnsCfg: GenericObject,
    previewCfg?: GenericObject,
    tableDictionary?: any,
    defaultPageSize?: number,
    manualPagination?: boolean,
    fetchData?: any,
    view?: 'table' | 'cards',
    enableMassActions?: boolean,
    previewDefaultPosition?: 'top' | 'bottom' | 'left' | 'right' | 'full' | 'cover',
    hidePagination?: boolean,
}


export const throttled = (delay: any, fn: any, ghostFn?: any) => {
    let lastCall = 0;
    return function (...args: any) {
        ghostFn?.(...args);
        const now = (new Date).getTime();
        if ((now - lastCall) < delay) {
            return;
        }
        lastCall = now;
        return fn(...args);
    }
}

export let getTableInstance: any = () => null;

export let rerenderTable = () => { };

export let getTableItemSelection = () => { };
export let setTableItemSelection = () => { };

export let getDataTable: any = () => { };
export let setDataTable: any = () => { };
export let setDataTableFn: any = async () => { };

export let getFilterCategory: any = () => { };
export let setFilterCategory: any = () => { };

export let setCF: any = () => { };

export let getLIPayload: any = () => { };
export let setLIPayload: any = () => { };

export let tableIsLoading: any = () => { };
export let setTableIsLoading: any = () => { };

export let getTableRef: any = () => { };
export let getTableDetailPanelRef: any = () => { };

export let getTablePreviewPosition: any = () => { };
export let setTablePreviewPosition: any = () => { };

export let getItemSelection: any = () => { };
export let updateItemSelection: any = () => { };

let setTimeoutFetchDataID: any = null;

let controller: any = null;

let lastRandom = 0;

let firstTableRows: any[] = [];

export function DataTable<TData>({
    data,
    columnsCfg,
    previewCfg,
    tableDictionary,
    defaultPageSize = 15,
    manualPagination = false,
    fetchData = null,
    view = 'table',
    enableMassActions = true,
    previewDefaultPosition = 'cover',
    hidePagination = false
}: DataTableProps<TData>) {
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const panelRef = React.useRef<any>(null);
    const tableHeaderRef = React.useRef<any>(null);

    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(columnsCfg?.initialState?.columnVisibility ?? {});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<SortingState>(columnsCfg?.sorting ?? []);
    const [itemSelection, setItemSelection]: any = React.useState({});
    const [panelDirection, setPanelDirection] = React.useState<any>('vertical');
    const [panelDetailCommandBarDirection, setPanelDetailCommandBarDirection] = React.useState<any>('vertical');
    const [previewPosition, setPreviewPosition] = React.useState<any>(previewDefaultPosition ?? 'cover');

    getTablePreviewPosition = () => previewPosition;
    setTablePreviewPosition = setPreviewPosition;

    getItemSelection = () => itemSelection;
    updateItemSelection = setItemSelection;

    getTableDetailPanelRef = () => panelRef;

    const columns = getColumns(columnsCfg, tableDictionary?.columns);

    const router: any = useRouter();

    const currentPage = parseInt(router?.query?.page ?? 0, 10);
    const currentPageSize = parseInt(router?.query?.pageSize ?? defaultPageSize, 10);
    const searchText = router?.query?.searchText;
    const subCategory = router?.query?.sub;
    const [pagination, setPagination]: any = React.useState({});

    const [pageCount, setPageCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    tableIsLoading = () => isLoading;
    setTableIsLoading = setIsLoading;
    const [lastIntegratedPayload, setLastIntegratedPayload] = React.useState({});
    getLIPayload = () => lastIntegratedPayload;
    setLIPayload = setLastIntegratedPayload;

    const [categoryFilters, setCategoryFilters]: any = React.useState({ data: null });
    setCF = setCategoryFilters;

    async function fetchDataFromApi(pagination: any = null, payload: any = {}, isLoadingHandler: boolean = true) {
        if (!fetchData) return;
        if (isLoadingHandler) {
            setIsLoading(true);
            setDetailIsOpen(false);
        }
        const integratedPayload = { ...payload, ...lastIntegratedPayload };
        const newPayload = {
            page: (pagination?.pageIndex === undefined) ? currentPage : (pagination?.pageIndex + 1),
            per_page: pagination?.pageSize ?? pagination?.[0]?.pageSize ?? defaultPageSize,
            search: searchText,
            ...integratedPayload
        };

        const result = await fetchData(newPayload, controller);

        if (columnsCfg?.onDataTable) {
            await columnsCfg?.onDataTable({ search: searchText, ...integratedPayload }, router, result.data, (pagination?.pageIndex + 1), controller);
        }

        /* const categoryFilters = await getFilterCategoryById(router?.query?.sub ?? router?.sub, payload);
        setCategoryFilters({
            data: { ...categoryFilters }
        }) */
        if (isLoadingHandler) {
            setIsLoading(false);
        }

        return result;
    };

    const [dataTable, setDT]: any = React.useState({ data: [] });
    const tableRef = React.useRef(null);

    getTableRef = () => tableRef;

    /* const dataTable = useSWR({ pageIndex: currentPage - 1, pageSize: currentPageSize, searchText: searchText }, fetchDataFromApi); */

    getDataTable = () => dataTable;
    setDataTable = setDT;

    setDataTableFn = (payload: any, callback?: any) => {
        setIsLoading(true);
        setDetailIsOpen(false);
        clearTimeout(setTimeoutFetchDataID);
        controller?.abort();
        setTimeoutFetchDataID = setTimeout(() => {
            (async () => {
                controller = new AbortController();
                const result = await fetchDataFromApi(pagination, payload, controller);

                /* setDT({ data: result?.data }); */
                const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                setPageCount(manualPagination ? -1 : pageLength);
                callback?.();

                setIsLoading(false);
            })();
        }, 1000);
    };

    getFilterCategory = () => categoryFilters.data;
    setFilterCategory = (data: any) => setCategoryFilters(data);

    const cardsTemplate = columnsCfg.cardsTemplate ?? 'standard';

    getTableItemSelection = () => itemSelection;
    setTableItemSelection = setItemSelection;

    const handleKeyPress = (e: any) => {
        const itemSelection = getItemSelection();
        console.log('itemSelection ===> ', itemSelection);
        const rowsModel = table?.getSortedRowModel();
        const rowList = rowsModel.rows;
        const selectedRowList: any = rowList.filter((row: any) => row?.original?.id === itemSelection?.original?.id);
        const selectedRow = selectedRowList?.[0];
        const rowIndex: number = rowList?.findIndex((row: any) => row?.original?.id === itemSelection?.original?.id);
        if ((selectedRow && !(Object.keys(selectedRow).length === 0))) {
            if (e.key === 'ArrowUp') {
                const prevRow = rowList[rowIndex - 1];
                if (!!prevRow) {
                    /* selectedRow.original.selection = false;
                    prevRow.original.selection = true;
                    setItemSelection(prevRow); */
                    handleItemSelection(prevRow);
                }
            }
            if (e.key === 'ArrowDown') {
                const nextRow = rowList[rowIndex + 1];
                if (!!nextRow) {
                    /* selectedRow.original.selection = false;
                    nextRow.original.selection = false;
                    setItemSelection(nextRow); */
                    handleItemSelection(nextRow);
                }
            }
        }
        /* const rowList = table.getSortedRowModel().rows;
        const firstRowList = firstTableRows;
        const selectedRow: any = rowList.filter((row: any) => row?.original?.selection)?.[0];
        const trueSelectedRow: any = firstRowList?.filter((row: any) => row?.original?.id === selectedRow?.original?.id)?.[0];
        const trueRowIndex: number = firstRowList?.findIndex((row: any) => row?.original?.id === trueSelectedRow?.original?.id);
        if ((selectedRow && !(Object.keys(selectedRow).length === 0))) {
            if (e.key === 'ArrowUp') {
                const truePrevRow = firstRowList[trueRowIndex - 1];
                const prevRow = rowList.filter((pr) => (pr.original.id === truePrevRow.original.id))?.[0];
                if (!!prevRow) {
                    selectedRow.original.selection = false;
                    prevRow.original.selection = true;
                    console.log('dataTable ===> ', getDataTable());
                    handleItemSelection(prevRow);
                }
            }
            if (e.key === 'ArrowDown') {
                const trueNextRow = firstRowList[trueRowIndex + 1];
                const nextRow = rowList.filter((pr) => (pr.original.id === trueNextRow.original.id))?.[0];
                if (!!nextRow) {
                    selectedRow.original.selection = false;
                    nextRow.original.selection = true;
                    console.log('dataTable ===> ', getDataTable());
                    handleItemSelection(nextRow);
                }
            }
        } */
    }

    React.useEffect(() => {
        if (firstTableRows?.length === 0) {
            firstTableRows = table.getSortedRowModel().rows;
        }
        console.log('firstTableRows ===> ', firstTableRows);
        const selectedRow = dataTable.data.filter((row: any) => row.id === itemSelection?.original?.id)[0];
        if (selectedRow) {
            setItemSelection({ ...itemSelection, original: selectedRow });
        }
    }, [dataTable]);



    React.useEffect(() => {
        panelRef?.current?.resize(100);
        (async () => {
            if (manualPagination && !!columnsCfg?.onTableScrollFn && (currentPage > 1)) {
                setAV('');
                setDetailIsOpen(false);
                table?.setPageIndex(0);
                return;
            }
            /* if (!!searchText) {
                const data = await fetchDataFromApi({ pageIndex: !manualPagination ? currentPage - 1 : 0, pageSize: currentPageSize, searchText: searchText });
                setDT({ data: data.data, pageCount: data.pageCount });
                setAV('');
                return;
            } */
            if (!manualPagination) {
                const data = await fetchDataFromApi({ pageIndex: currentPage - 1, pageSize: currentPageSize });
                setDT({ data: data.data, pageCount: data.pageCount });
                setAV('');
                return;
            }
        })()
        window.addEventListener("keydown", throttled(750, handleKeyPress, (e: any) => ((e.key === 'ArrowUp') || (e.key === 'ArrowDown')) ? e.preventDefault() : null), false);
        return () => {
            setFilterCategory({ data: null });
            setBF([]);
            setSF([]);
            setGF([]);
            setSelectedF([]);
            setSBF([]);
            setSGF([]);
            setSSF([]);
            setLastIntegratedPayload({});
            setDT({ data: [] });
            setPagination({});
            setPageCount(0);
            setDetailIsOpen(false);
            window.addEventListener("keydown", throttled(750, handleKeyPress, (e: any) => ((e.key === 'ArrowUp') || (e.key === 'ArrowDown')) ? e.preventDefault() : null), false);
        };
    }, []);

    React.useEffect(() => {
        (async () => {
            console.log(pagination?.firstTime);
            if (!!router?.query?.sub /* && (pagination?.firstTime === true) */) {
                const tableScrollAreaRef: any = getTableScrollAreaRef();
                tableScrollAreaRef?.current?.scrollTo({ top: 0, behavior: 'auto' });
                setIsLoading(true);
                setDT({ data: [], pageCount: 0 });
                const data = await fetchDataFromApi({ pageIndex: 0, pageSize: currentPageSize });
                setDT({ data: data.data, pageCount: data.pageCount });
                setAV('');
            }
        })()
    }, [router?.query?.sub]);

    React.useEffect(() => {
        (async () => {
            if ((!!searchText || searchText === '') && ((pagination?.firstTime === false) || !!!subCategory)/*  && !!dataTable?.data?.length */) {
                const tableScrollAreaRef: any = getTableScrollAreaRef();
                tableScrollAreaRef?.current?.scrollTo({ top: 0, behavior: 'auto' });
                setIsLoading(true);
                await setDT({ data: [], pageCount: 0 });
                await setAV('');
                const canNextPage = (dataTable?.data?.length <= (router?.query?.page * router?.query?.pageSize));
                if (canNextPage) {
                    setDetailIsOpen(false);
                    table?.setPageIndex(0);
                } else {
                    const data = await fetchDataFromApi({ pageIndex: 0, pageSize: currentPageSize, searchText: searchText });
                    setDT({ data: data.data, pageCount: data.pageCount });
                }
            }
        })()
    }, [searchText]);

    React.useEffect(() => {
        if (categoryFilters?.data?.brands?.length > 0) {
            setBF(categoryFilters?.data?.brands);
        }
        if (categoryFilters?.data?.grids?.length > 0) {
            setGF(categoryFilters?.data?.grids);
        }
        if (categoryFilters?.data?.specs?.length > 0) {
            setSF(categoryFilters?.data?.specs);
        }
    }, [categoryFilters?.data?.brands, categoryFilters?.data?.specs]);

    React.useEffect(() => {
        (async () => {
            if (currentPage && currentPageSize && dataTable?.data) {
                if (dataTable?.data?.length === 0) {
                    const result = dataTable ?? { data: [] };
                    setDT({ data: result?.data });
                    const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                    setPageCount(manualPagination ? -1 : pageLength);
                    setPagination({ pageIndex: currentPage - 1, pageSize: currentPageSize, firstTime: manualPagination ? false : true });
                    //setIsLoading(false);
                } else {
                    if (!!!columnsCfg?.onTableScrollFn) {
                        const result = dataTable;
                        setDT({ data: result?.data });
                        if (manualPagination) {
                            const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                            setPageCount(manualPagination ? -1 : pageLength);
                        }
                        setPagination({ pageIndex: currentPage - 1, pageSize: currentPageSize, firstTime: false });
                        setPageCount(manualPagination ? -1 : Math.ceil(dataTable?.data.length / currentPageSize));
                    }
                    /* if (manualPagination) {
                        const result = dataTable?.data;
                        setDT({data: result?.data});
                        const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                        setPageCount(manualPagination ? -1 : pageLength);
                        //setIsLoading(false);
                    }
                    setPagination({ pageIndex: currentPage - 1, pageSize: currentPageSize, firstTime: false });
                    setPageCount(manualPagination ? -1 : Math.ceil(dataTable?.data.length / currentPageSize)); */
                }
            }
        })();
    }, [currentPage, currentPageSize, subCategory]);

    React.useEffect(() => {
        if (itemSelection?.original?.id) {
            dataTableSheetModel.setOpen(true);
        } else {
            dataTableSheetModel.setOpen(false);
        }
    }, [itemSelection])

    React.useEffect(() => {
        if (!!Object.keys(lastIntegratedPayload)?.length && columnsCfg?.onTableScrollFn && !!!dataTable?.data?.length) {
            const tableScrollAreaRef: any = getTableScrollAreaRef();
            tableScrollAreaRef?.current?.scrollTo({ top: 0, behavior: 'auto' });
            const canNextPage = (dataTable?.data?.length <= (router?.query?.page * router?.query?.pageSize));
            if (canNextPage) {
                setDetailIsOpen(false);
                table?.setPageIndex(0);
            }
        }
    }, [lastIntegratedPayload])

    const table = useReactTable({
        data: (fetchData) ? dataTable?.data : data,
        columns,
        pageCount: pageCount,
        state: {
            sorting,
            columnVisibility: columnVisibility,
            rowSelection,
            columnFilters,
            pagination: pagination,
        },
        enableRowSelection: true,
        manualPagination: manualPagination,
        enableSortingRemoval: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        onPaginationChange: async (updater) => {
            let newPagination = (typeof updater === 'function') ? updater(pagination) : updater;
            newPagination = (pagination.firstTime) ? pagination : newPagination;
            setPagination({ ...newPagination, firstTime: false });
            if (!manualPagination) {
                let pageLength: number = Math.ceil(dataTable?.data?.length / (newPagination?.pageSize ?? defaultPageSize));
                setPageCount(pageLength);
            } else {
                setPageCount(-1);
            }
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    page: newPagination.pageIndex + 1,
                    pageSize: (newPagination?.pageSize ?? defaultPageSize),
                },
            });

            if (manualPagination) {
                if (!!columnsCfg?.onTableScrollFn && (dataTable?.data?.length <= (router?.query?.page * router?.query?.pageSize)) && (newPagination.pageIndex > 0)) {
                    setIsLoading(true);
                    const data = await fetchDataFromApi(newPagination, {}, false);
                    const newData = { data: dataTable?.data.concat(data?.data) };
                    setDT(newData);
                    setIsLoading(false);
                } else {
                    clearTimeout(setTimeoutFetchDataID);
                    controller?.abort();
                    setTimeoutFetchDataID = setTimeout(() => {
                        (async () => {
                            controller = new AbortController();
                            setIsLoading(true);
                            const data = await fetchDataFromApi(newPagination, {}, false);
                            setDT({ data: data?.data });
                            setIsLoading(false);
                        })();
                    }, 1500);
                    /* const data = await fetchDataFromApi(newPagination);
                    setDT({ data: data?.data }); */
                }
            };
        },
    })

    const { rows } = table?.getRowModel();

    getTableInstance = () => table;

    const rowVirtualizer = useVirtualizer({
        count: rows?.length,
        estimateSize: () => 1, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            (typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1) ?
                element => {
                    const rowHeight = element?.getBoundingClientRect().height;
                    console.log('rowHeight ===> ', rowHeight);
                    return rowHeight;
                }
                : undefined,
        overscan: 20,
    })

    rerenderTable = React.useReducer(() => ({}), {})[1];

    const handleItemSelection = (row: any = null) => {
        if (previewCfg?.disabled) return;
        const dt: any = getDataTable() ?? { data: [] };
        if (row?.original?.id === itemSelection?.original?.id) {
            row.original.selection = false;
            setItemSelection({});
            /* const newDT = dt.data.map((r: any) => {
                r.selection = false;
                return r;
            });
            setDT({ ...dt, data: newDT }); */
            if (previewPosition !== 'cover') {
                panelRef?.current?.resize?.(100);
            }
        } else {
            row.original.selection = true;
            setItemSelection(row);
            /* const newDT = dt.data.map((r: any) => {
                if (r.id === row.original.id) {
                    r.selection = true;
                } else {
                    r.selection = false;
                }
                return r;
            });
            setDT({ ...dt, data: newDT });*/
            if (previewPosition !== 'cover') {
                panelRef?.current?.resize?.(40);
            }
            /* console.log('current ref ===> ', tableContainerRef?.current);
            console.log('current ref top ===> ', tableContainerRef?.current?.children?.[0]);
            console.log('current ref top ===> ', tableContainerRef?.current?.children?.[0]?.querySelectorAll('table'));
            console.log('current ref top ===> ', tableContainerRef?.current?.querySelectorAll('tr.active-row')?.[0]);
            console.log('current ref top ===> ', tableContainerRef?.current?.querySelectorAll('tr.active-row')?.[0]?.clientHeight);
            console.log('current ref top ===> ', tableContainerRef?.current?.querySelectorAll('tr.active-row')?.[0]?.scrollHeight);
            console.log('current ref top ===> ', row.index); */
            setTimeout(() => {
                const rowList = table.getSortedRowModel().rows;
                const rowIndex: number = rowList?.findIndex((r: any) => r?.original?.id === row?.original?.id);
                const rowScrollHeight = tableContainerRef?.current?.querySelectorAll('tr.active-row')?.[0]?.scrollHeight ?? 1;
                const howToScroll = (rowIndex > 0) ? ((rowIndex - 1) * rowScrollHeight) : 0;
                tableContainerRef?.current?.children?.[0].scrollTo({ top: howToScroll, behavior: 'smooth' });
            }, 0);
        }
    }

    const actionsTemplate = (row: any, columnsCfg: any, tableColumnsDictionary: any = null) => {
        const columnActions = columnsCfg?.columns.filter((column: any) => column.name === 'actions')[0];
        if (columnActions) {
            return (
                <div className="flex flex-row justify-between items-center">
                    <label htmlFor="" className="text-foreground font-bold">Actions:</label>
                    <div className="flex items-center space-x-2">
                        {
                            columnActions?.actions.filter((action: any) => !action.subMenu).map((action: any, index: number) => {
                                return (
                                    <Tooltip key={action.name + '-' + index} delayDuration={0}>
                                        <TooltipTrigger>
                                            <Badge variant="outline" key={action.name + '-' + index} className={"cursor-pointer block"} onClick={(e) => { e.stopPropagation(); action.onClick(row.original, action.name) ?? (() => { }) }}>
                                                {
                                                    ((row.original?.status?.state === 'pending') && (action.name === row.original?.status?.name)) &&
                                                    <LoaderCircleIcon name={action.name} size={action.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                                }
                                                {
                                                    ((row.original?.status?.state === 'success') && (action.name === row.original?.status?.name)) &&
                                                    <CircleCheckIcon name={action.name} size={action.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                                }
                                                {
                                                    ((row.original?.status?.state === 'error') && (action.name === row.original?.status?.name)) &&
                                                    <CircleXIcon name={action.name} size={action.size ?? 18} className={'stroke-2 fill-red-600 stroke-secondary scale-125'} />
                                                }
                                                {
                                                    ((!row.original?.status?.state) || !(action.name === row.original?.status?.name)) &&
                                                    <action.icon name={action.name} size={action.size ?? 18} className={action.className + ' ' + ((row.original?.[action.classNameConditionKey]) && action.classNameActive)} />
                                                }
                                            </Badge>
                                        </TooltipTrigger>
                                        <TooltipContent side="bottom" className="flex items-center gap-4">
                                            {
                                                (() => {
                                                    const tooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.tooltip ?? '';
                                                    const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.disabledTooltip ?? '';
                                                    return !(row.original?.[action.classNameConditionKey]) ? tooltip : disabledTooltip;
                                                })()
                                            }
                                        </TooltipContent>
                                    </Tooltip>
                                )
                            })
                        }
                        <DataTableRowActions row={row} actions={columnActions?.actions?.filter((action: any) => action?.subMenu)} />
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <div ref={tableRef} className="flex flex-1 relative overflow-hidden !mt-0">
            {
                (isLoading && (!!columnsCfg?.onTableScrollFn && ((currentPage === 1) && (getDataTable()?.data?.length < currentPageSize)))) ?
                    <div className="animate-gradient absolute w-full h-full top-0 left-0 z-10">
                        <img
                            fetchpriority="high"
                            src="/images/table_loading.png"
                            alt="Authentication"
                            className="block dark:hidden w-full h-full"
                        />
                    </div>
                    :
                    (!isLoading && (getDataTable()?.data?.length === 0) && (currentPage === 1)) ?
                        <div className="flex flex-1 w-full justify-center items-center">
                            nessun articolo trovato
                        </div>
                        :
                        <div className="space-y-4 flex flex-col flex-1 m-1 w-full">
                            {
                                (enableMassActions) ?
                                    <div className={'space-y-0' + ' ' + (table.getIsSomeRowsSelected() ? 'opaciy-100' : 'opacity-25 pointer-events-none')}>
                                        {
                                            columnsCfg?.massiveActions?.map((massiveAction: any, index: number) => {
                                                return (
                                                    <Tooltip key={massiveAction.name + '-' + index} delayDuration={0}>
                                                        <TooltipTrigger>
                                                            <Badge variant="outline" className={"cursor-pointer mr-2"} onClick={() => { massiveAction.onClick() }}>
                                                                {(massiveAction?.iconLeft) ? <massiveAction.iconLeft size={massiveAction.size ?? 18} className={'mr-1' + ' ' + massiveAction.className} /> : null}
                                                                {(massiveAction?.icon) ? <massiveAction.icon size={massiveAction.size ?? 18} className={massiveAction.className} /> : null}
                                                                {(massiveAction?.iconRight) ? <massiveAction.iconRight size={massiveAction.size ?? 18} className={'ml-1' + ' ' + massiveAction.className} /> : null}
                                                            </Badge>
                                                        </TooltipTrigger>
                                                        <TooltipContent side="top" className="flex items-center gap-4">
                                                            {tableDictionary?.massiveActions?.[massiveAction.name]?.tooltip}
                                                        </TooltipContent>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    null
                            }
                            <DataTableToolbar view={view} table={table} columns={columns} columnsCfg={columnsCfg} tableDictionary={tableDictionary} />
                            <ResizablePanelGroup
                                id="group"
                                direction={panelDirection}
                                className="w-full"
                                onResize={(sizes) => { }}
                                autoSaveId={'table-resizable-panel-group' + (router.pathname?.replaceAll('/', '-'))}
                                onLayout={(sizes: number[]) => { }}
                            >
                                <ResizablePanel ref={panelRef} defaultSize={100} onResize={(size) => { }}>
                                    <div ref={tableContainerRef} className="rounded-md flex flex-1 overflow-hidden !mt-0 h-full">
                                        {
                                            (view === 'table') ?
                                                (isLoading) ?
                                                    <div className={
                                                        cn(
                                                            "loader transition-all left-0 flex justify-center items-center w-full h-full",
                                                            isLoading ? 'opacity-100' : 'opacity-0'
                                                        )}
                                                    >
                                                        <div className="hidden"></div>
                                                        <div className="m-0 p-0">
                                                            <LoadingPanel />
                                                        </div>
                                                    </div>
                                                    :
                                                    (
                                                        !!table.getHeaderGroups().length ?

                                                            <Table style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                                                <TableHeader className="z-10 bg-background top-0" style={{ position: 'sticky' }}>
                                                                    {table.getHeaderGroups().map((headerGroup: any) => (
                                                                        <TableRow key={headerGroup.id} ref={tableHeaderRef}>
                                                                            {headerGroup.headers.map((header: any) => {
                                                                                return (
                                                                                    <TableHead key={header.id} colSpan={header.colSpan} style={{ width: headerGroup?.getSize?.() }}>
                                                                                        {header.isPlaceholder
                                                                                            ? null
                                                                                            : flexRender(
                                                                                                header.column.columnDef.header,
                                                                                                header.getContext()
                                                                                            )}
                                                                                    </TableHead>
                                                                                )
                                                                            })}
                                                                        </TableRow>
                                                                    ))}
                                                                </TableHeader>
                                                                <TableBody>
                                                                    {table?.getRowModel()?.rows?.length ? (
                                                                        rowVirtualizer.getVirtualItems().map((virtualRow: any, index: number) => {
                                                                            /* table.getRowModel().rows.map((virtualRow: any, index: number) => { */
                                                                            const row: any = rows[virtualRow?.index];
                                                                            /* const row: any = virtualRow; */
                                                                            return (
                                                                                <TableRow
                                                                                    key={row?.id + '-' + index}
                                                                                    data-state={row?.getIsSelected() && "selected"}
                                                                                    className={"cursor-pointer" + " " + (itemSelection?.original?.id === row?.original?.id ? 'active-row' : '')}
                                                                                    onClick={() => {
                                                                                        handleItemSelection(row);
                                                                                    }}
                                                                                    style={{
                                                                                        height: `${virtualRow.size}px`,
                                                                                        transform: `translateY(${virtualRow.start - index * virtualRow.size
                                                                                            }px)`,
                                                                                    }}
                                                                                /* style={{ top: ((tableHeaderRef?.current?.offsetHeight) ? (tableHeaderRef?.current?.offsetHeight - 1) : 0) }} */
                                                                                >
                                                                                    {row?.getVisibleCells().map((cell: any, i: number) => (
                                                                                        <TableCell key={cell.id + '-' + i}>
                                                                                            {flexRender(
                                                                                                cell.column.columnDef.cell,
                                                                                                cell.getContext()
                                                                                            )}
                                                                                        </TableCell>
                                                                                    ))}
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell
                                                                                colSpan={columns.length}
                                                                                className="h-24 text-center"
                                                                            >
                                                                                {tableDictionary?.messages?.noResults ?? 'No data'}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>

                                                            :
                                                            <div className="w-full h-full flex items-center justify-center text-gray-500">
                                                                Nessun dato da mostrare
                                                            </div>
                                                    )
                                                :
                                                (() => {
                                                    let cardsComponent = null;
                                                    switch (cardsTemplate) {
                                                        case 'standard':
                                                            return cardsComponent = <DataCards enableMassActions={enableMassActions} table={table} cardCfg={columnsCfg} tableDictionary={tableDictionary} itemSelection={itemSelection} itemSelectionFn={handleItemSelection}></DataCards>
                                                        case 'products':
                                                            return cardsComponent = (
                                                                <div className={cn(
                                                                    "flex flex-col flex-1 relative",

                                                                )}>
                                                                    <DataProductCards tableScrollHandler={columnsCfg?.onTableScrollFn?.()} enableMassActions={enableMassActions} table={table} cardCfg={columnsCfg} tableDictionary={tableDictionary} itemSelection={itemSelection} itemSelectionFn={handleItemSelection}>
                                                                        {
                                                                            (!!columnsCfg?.onTableScrollFn && !isLoading && (dataTable?.data?.length > 0) && !((dataTable?.data?.length) >= ((+router?.query?.page * +router?.query?.pageSize)))) &&
                                                                            <div
                                                                                className={cn(
                                                                                    "loader left-0 w-full flex justify-center items-center pt-5 pb-4 mt-4"
                                                                                )}
                                                                            ></div>
                                                                        }
                                                                    </DataProductCards>
                                                                    {
                                                                        !!columnsCfg?.onTableScrollFn &&
                                                                            (!(!isLoading && (dataTable?.data?.length > 0) && !((dataTable?.data?.length) >= ((+router?.query?.page * +router?.query?.pageSize))))) ?
                                                                            <div className={
                                                                                cn(
                                                                                    "loader absolute transition-all left-0 w-full flex justify-center items-center bg-primary text-primary-foreground pt-2 pb-2",
                                                                                    (isLoading && (!!columnsCfg?.onTableScrollFn && ((currentPage > 1) && (getDataTable()?.data?.length >= currentPageSize)))) ? 'bottom-0' : 'bottom-[-100px]'
                                                                                )}>
                                                                                <div role="status">
                                                                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                                    </svg>
                                                                                    <span className="sr-only text-primary-foreground">Loading...</span>
                                                                                </div>
                                                                                <label className="ml-3">Loading...</label>
                                                                            </div>
                                                                            :
                                                                            <div
                                                                                className={cn(
                                                                                    "loader absolute transition-all left-0 w-full flex justify-center items-center bg-primary text-primary-foreground pt-2 pb-2 bottom-0"
                                                                                )}
                                                                            >
                                                                                Non ci sono altri articoli da visualizzare
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            return cardsComponent = <DataCards enableMassActions={enableMassActions} table={table} cardCfg={columnsCfg} tableDictionary={tableDictionary} itemSelection={itemSelection} itemSelectionFn={handleItemSelection}></DataCards>
                                                    }
                                                })()
                                        }
                                    </div>
                                </ResizablePanel>
                                <ResizableHandle withHandle className={cn(
                                    ((previewPosition !== 'cover') && (Object.keys(itemSelection).length > 0)) ? '' : 'hidden'
                                )} />
                                <ResizablePanel defaultSize={0}>
                                    {
                                        previewPosition === 'attached' ?
                                            <DataTableDetail
                                                title={'ID: ' + itemSelection?.original?.id}
                                                description={''}
                                                actions={actionsTemplate(itemSelection, columnsCfg, tableDictionary?.columns)}
                                                data={itemSelection}
                                                previewCfg={previewCfg}
                                                tableDictionary={tableDictionary}
                                                onCloseCallback={() => {
                                                    panelRef.current.resize(100);
                                                    handleItemSelection(itemSelection);
                                                }}
                                                layoutDirection={panelDirection}
                                                onPanelDetachCallback={() => previewCfg?.onPanelDetachCallback()}
                                                onPanelResizeCallback={() => {
                                                    switch (panelDirection) {
                                                        case 'horizontal':
                                                            setPanelDirection('vertical');
                                                            setPanelDetailCommandBarDirection('vertical');
                                                            panelRef.current.resize(40);
                                                            break;
                                                        case 'vertical':
                                                            panelRef.current.resize(60);
                                                            setPanelDirection('horizontal');
                                                            setPanelDetailCommandBarDirection('horizontal');
                                                            break;
                                                        default:
                                                            setPanelDirection('vertical');
                                                            setPanelDetailCommandBarDirection('vertical');
                                                            panelRef.current.resize(40);
                                                            break;
                                                    }
                                                }}
                                                commandBarDirection={panelDetailCommandBarDirection}
                                            ></DataTableDetail> : null
                                    }
                                </ResizablePanel>
                            </ResizablePanelGroup>

                            {
                                ((!columnsCfg?.onTableScrollFn) && (!hidePagination || !!columnsCfg?.hidePagination)) ?
                                    <DataTablePagination table={table} tableDictionary={tableDictionary} paginationState={pagination} />
                                    :
                                    null
                            }
                        </div>
            }
            {
                previewPosition === 'cover' ?
                    <DataTableSheet
                        side={previewCfg?.sideFn?.() ?? ((view === 'table') ? 'right' : 'top')}
                        title={'ID: ' + itemSelection?.original?.id}
                        description={''}
                        actions={actionsTemplate(itemSelection, columnsCfg, tableDictionary?.columns)}
                        data={itemSelection}
                        onOpenCallback={() => { }}
                        onCloseCallback={() => setItemSelection({})}
                        previewCfg={previewCfg}
                        tableDictionary={tableDictionary}
                        onPanelAttachCallback={previewCfg?.onPanelAttachCallback}
                    ></DataTableSheet>
                    :
                    null
            }
        </div>
    )
}
