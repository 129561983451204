"use client"

import * as React from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button";
import { Columns2Icon, FastForwardIcon, PinOffIcon, Rows2Icon } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import LoadingPanel from "@/components/loading-panel";

export let dataTableSheetModel = { open: false, setOpen: (value: boolean) => { } };

const getSidePreviewTemplate = (row: any, previewCfg: any, tableDictionary: any, dataDetails: any, commandBarDirection?: 'vertical' | 'horizontal') => {
    if (!row?.original) return <div>no data!</div>;
    return (
        <div
            className={cn(
                "h-full flex flex-col flex-1 overflow-y-auto",
                (previewCfg?.view() === 'COLUMN') ? "" : "items-center justify-center"
            )}
        >
            {
                (previewCfg?.main) ?
                    <div
                        className={cn(
                            "w-full h-full flex flex-col",
                            (previewCfg?.view() === 'COLUMN') ? "" : "h-full",
                        )}
                        style={{ margin: '2.5rem 0' }}
                    >
                        {previewCfg?.main ? previewCfg?.main(row, tableDictionary, dataDetails) : ''}
                    </div>
                    :
                    null
            }

            {
                (previewCfg?.view() === 'COLUMN') &&
                <Tabs defaultValue={previewCfg.tabDefaultValue ?? previewCfg?.tabs?.[0]?.name ?? ''} className="flex flex-col flex-1 overflow-hidden">
                    <TabsList className="grid w-full flex flex-row justify-start p-0 bg-white border-b-2 border-gray-200">
                        {
                            previewCfg?.tabs?.map((tab: any, index: number) => {
                                return (
                                    <TabsTrigger key={tab.name + ' ' + index} className="" value={tab.name}>{tableDictionary?.sheet?.tabs?.[tab.name] ?? tab.label}</TabsTrigger>
                                )
                            })
                        }
                    </TabsList>
                    <div className="overflow-hidden h-full w-full">
                        {
                            previewCfg?.tabs?.map((tab: any, index: number) => {
                                return (
                                    <TabsContent key={tab.name + ' ' + index} className="mt-0 h-full border-l-2 border-l-gray-200 bg-white" value={tab.name}>
                                        {tab.template(row, tableDictionary, dataDetails, commandBarDirection)}
                                    </TabsContent>
                                )
                            })
                        }
                    </div>
                </Tabs>
            }
        </div>
    );
}

export let setDS: any = () => { };
export let setDetailIsOpen: any = () => { };

export let getDetailIsLoading = () => { };
export let setDetailIsLoading = (value: boolean) => { };

export let getTableDataDetails = () => { };
export let setTableDataDetails = (data: any) => { };

export function DataTableDetail({ ...props }: any) {
    const [open, setOpen] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false);
    const [dataDetails, setDataDetails] = React.useState<any>(null);
    const [detailsStyle, setDetailsStyle] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    setDS = setDetailsStyle;
    setDetailIsOpen = setOpen;

    getTableDataDetails = () => dataDetails;
    setTableDataDetails = (data: any) => setDataDetails(data);

    getDetailIsLoading = () => {
        return isLoading;
    }
    setDetailIsLoading = (value: boolean) => {
        return setIsLoading(value);
    };

    const resize = () => {
        props.previewCfg?.onResize?.();
    }

    React.useEffect(() => {
        console.log("row data ===> ", !!props.data);
        if (!!props.data) {
            if (!!Object.keys(props.data).length && props.previewCfg?.getDataDetailsFn) {
                (async () => {
                    setIsLoading(true);
                    const data = await props.previewCfg?.getDataDetailsFn?.(props.data, 'DETAIL');
                    setDataDetails(data);
                    setIsLoading(false);
                })();
            }
        }
    }, [props.data])

    React.useEffect(() => {
        dataTableSheetModel.open = open;
        dataTableSheetModel.setOpen = setOpen;
        window.addEventListener('resize', resize);
        resize();
        setIsLoading(false);
        return () => {
            setDataDetails(null);
            window.removeEventListener('resize', resize);
        }
    }, [])

    return (
        <div className="h-full">
            <div
                className={"p-0" + " " + ((props.side === 'right') ? "sm:max-w-[60%] w-[60%]" : "sm:max-w-full w-full h-full") + " " + ((expanded) ? 'sm:max-w-full w-full h-full' : '')}
                style={detailsStyle ?? {}}
            >

                {
                    !isLoading ?
                        <div className={cn(
                            "flex flex-1 h-full transition-all",
                            isLoading ? 'opacity-0' : 'opacity-100',
                            props.commandBarDirection === 'vertical' ? 'flex-row-reverse' : 'flex-col',
                        )}>
                            <div className={cn(
                                "flex space-y-0 dark:bg-primary-foreground",
                                (props.commandBarDirection === 'vertical') ? 'bg-gray-100 flex-col-reverse justify-end' : 'bg-gray-100 flex-row justify-end',
                            )}>
                                <div>
                                    {
                                        (!!dataDetails?.categoryTree?.length) ?
                                            <div className="flex flex-row w-full my-2 pl-1 text-xs">
                                                {
                                                    dataDetails?.categoryTree?.map?.((c: any, index: number) => {
                                                        return (
                                                            <div key={index} className="banner before:bg-primary-foreground">
                                                                <div className="">
                                                                    <label htmlFor="">{c.description}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="m-0 p-0 hidden">
                                    {props.description}
                                </div>
                                <div className={cn(
                                    "flex flex-row",
                                    props.layoutDirection === 'vertical' ? '' : ''
                                )}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                className="ml-full px-3"
                                                variant="ghost"
                                                onClick={() => props.onPanelDetachCallback()}
                                            >
                                                <PinOffIcon className="w-5 h-5" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent side={(props.commandBarDirection === 'vertical') ? "left" : "bottom"} className="flex items-center gap-4">
                                            Sposta all&apos;esterno
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                                <div className={cn(
                                    "flex flex-row ",
                                    props.layoutDirection === 'vertical' ? '' : ''
                                )}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                className="ml-full px-3"
                                                variant="ghost"
                                                onClick={() => props.onPanelResizeCallback()}
                                            >
                                                {
                                                    props.layoutDirection === 'vertical' ?
                                                        <Columns2Icon className="w-5 h-5" />
                                                        :
                                                        <Rows2Icon className="w-5 h-5" />
                                                }
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent side={(props.commandBarDirection === 'vertical') ? "left" : "bottom"} className="flex items-center gap-4">
                                            {
                                                props.layoutDirection === 'vertical' ?
                                                    'Visualizza in orizzontale'
                                                    :
                                                    'Visualizza in verticale'
                                            }
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                                <div className={cn(
                                    "flex flex-row",
                                    props.layoutDirection === 'vertical' ? '' : ''
                                )}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                className="ml-full px-3"
                                                variant="ghost"
                                                onClick={() => props.onCloseCallback(null)}
                                            >
                                                <FastForwardIcon className="w-5 h-5 origin-center rotate-180" />
                                                {/* <Cross1Icon className="w-5 h-5" /> */}
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent side={(props.commandBarDirection === 'vertical') ? "left" : "bottom"} className="flex items-center gap-4">
                                            Chiudi dettaglio
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="grid gap-4 border-t overflow-hidden flex flex-col flex-1">
                                {props.children}
                                {getSidePreviewTemplate(props.data, props.previewCfg, props.tableDictionary, dataDetails, props.commandBarDirection)}
                            </div>
                            <div>
                                {
                                    props.actions &&
                                    <div className="w-full p-4 border-t">
                                        {props.actions}
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className={
                            cn(
                                "loader transition-all left-0 flex justify-center items-center w-full h-full",
                                isLoading ? 'opacity-100' : 'opacity-0'
                            )}
                        >
                            <div className="hidden"></div>
                            <div className="m-0 p-0">
                                <LoadingPanel />
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
