import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
} from "@radix-ui/react-icons"
import { Table } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

interface DataTablePaginationProps<TData> {
    table: Table<TData>
    tableDictionary?: any,
    paginationState?: any
}

export function DataTablePagination<TData>({
    table,
    tableDictionary,
    paginationState
}: DataTablePaginationProps<TData>) {
    return (
        <div className="flex items-center justify-between px-2 gap-2 !mt-2">
            <div className="flex-1 text-sm text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length} {tableDictionary?.pagination?.of ?? 'of'}{" "}
                {table.getFilteredRowModel().rows.length} {tableDictionary?.pagination?.['rows-selected'] ?? 'row(s) selected.'}
            </div>
            <div className="flex items-center space-x-6 lg:space-x-8">
                <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">{tableDictionary?.pagination?.['rows-per-page'] ?? 'Rows per page'}</p>
                    <Select
                        value={paginationState?.pageSize?.toString() ?? `${table.getState().pagination.pageSize}`}
                        onValueChange={(value: any) => {
                            table.setPageSize(Number(value))
                        }}
                    >
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={table.getState().pagination.pageSize} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[15, 20, 30, 40, 50, 1000].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex w-[110px] items-center justify-center text-sm font-medium truncate text-ellipsis">
                    {tableDictionary?.pagination?.['page'] ?? 'Page'} {table.getState().pagination.pageIndex + 1} {tableDictionary?.pagination?.['of'] ?? 'of'}{" "}
                    {table?.options?.pageCount ?? table.getPageCount()}
                </div>
                <div className="flex items-center space-x-2">
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">{tableDictionary?.pagination?.['first'] ?? 'Go to first page'}</span>
                        <DoubleArrowLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">{tableDictionary?.pagination?.['previous'] ?? 'Go to previous page'}</span>
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">{tableDictionary?.pagination?.['next'] ?? 'Go to next page'}</span>
                        <ChevronRightIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">{tableDictionary?.pagination?.['last'] ?? 'Go to last page'}</span>
                        <DoubleArrowRightIcon className="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </div>
    )
}
