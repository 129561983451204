import React, { useContext, useEffect, useState } from 'react';

import { NavProps } from '@/models/NavigationModel';
import { Separator } from '@/components/ui/separator';
import { onSidebarClickHandler2 } from '@/services/api/navigation.service';
import { useParams, usePathname } from 'next/navigation';
import { TranslationsContext } from '@/components/providers/TranslationsProvider';
import { GenericObject } from '@/models/UtilsModel';
import { useRouter } from 'next/router';
import Nav from '@/components/presentational/Nav';

export let sidebarState: GenericObject = () => { };

const Sidebar = ({ isCollapsed = false }: NavProps) => {
    const pathName = usePathname();

    const router = useRouter();

    const [sidebarData, setSidebarData] = useState<GenericObject>([]);

    const { dictionary }: GenericObject = useContext(TranslationsContext);

    /* const params: any = useParams<{ workspace: string; category: string; sub: string }>(); */

    sidebarState = { get: sidebarData, set: setSidebarData };

    useEffect(() => {
        if (router?.query) {
            onSidebarClickHandler2()(router?.query, pathName, router).then((result) => {
                sidebarState.set(result);
            });
        }
    }, [pathName, router?.query?.category, router?.query?.sub]);

    useEffect(() => {
        /* navigationService?.then((result) => {
            sidebarState.set(result);
        }) */
    }, [dictionary])

    return (
        <aside className="sidebar w-full h-full flex flex-col flex-1 overflow-hidden">
            {/* <Separator /> */}
            <Nav
                dictionary={dictionary}
                isCollapsed={isCollapsed}
                links={sidebarState.get ?? []}
            />
            {/* <Separator />
            <Nav
                dictionary={dictionary}
                isCollapsed={isCollapsed}
                links={sidebarData?.sidebarList2 ?? []}
            /> */}
        </aside>
    );
};

export default Sidebar;
