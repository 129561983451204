import axiosInterceptorInstance from "../axiosInterceptorInstance";

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getFilterCategoryById = async (id: string, payload: any, controller?: any) => {
    const url: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/categories/data');
    const sort = JSON.parse(payload.sort ?? '{}');
    let sortPayload: any = {
        sale_variation: (sort?.sale_variation === 'asc') ? true : false,
        purchase_variation: (sort?.purchase_variation === 'asc') ? true : false,
        stock_stores: payload?.stock_stores ?? null,
        exclusive: payload?.exclusive ?? false,
    };
    const response: any = await axiosInterceptorInstance.post(url, { id_category: id, ...payload, ...sortPayload }, { signal: controller?.signal });
    const remappedFilters = await filterCategoryListRemapper(response?.data?.response, id);

    /* const url2: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/categories/brands');
    const response2 = await axiosInterceptorInstance.get(url2, { params: { id_category: id } });
    await timeout(2000);
    const url3: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/specifications/category_values');
    const response3 = await axiosInterceptorInstance.post(url3, { id_category: id, specs: payload?.specs });

    const remappedFilters = await filterCategoryListRemapper({ brands: response2?.data?.response, specifications: response3?.data?.response }, id); */

    /* let results: any[] = [];

    for await (const spec of remappedFilters.specs) {
        console.log(spec);
        await timeout(1000);
        const remappedSpecData = await getSpecFiltersById(id, spec.id);
        spec.filters = remappedSpecData;
        results.push(spec);
    }

    remappedFilters.specs = results; */

    return remappedFilters;
};

export const getSpecFiltersById = async (idCategory: string, idSpec: string) => {
    const url: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/specifications/values');
    const response = await axiosInterceptorInstance.post(url, { id_category: idCategory, id_spec: idSpec });
    const data = Object.entries(response?.data?.response ?? {}).map((spec: any) => {
        return {
            id: spec[0],
            label: spec[1].value,
            count: spec[1].count
        }
    })
    return data;
};

const filterCategoryListRemapper = async (dataList: any, categoryId: string) => {
    const remappedFilters = {
        ...dataList,
        brands: [
            {
                category: "Brands",
                label: "Brands",
                filters: Object.entries(dataList?.brands ?? {}).map((brand: any) => {
                    return {
                        id: brand[0],
                        label: brand[1].value,
                        count: brand[1].count
                    }
                }).sort((a: any, b: any) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                })
            }
        ].filter((brand: any) => brand.filters.length > 0),
        grids: [
            {
                category: "Grids",
                label: "Stato",
                filters: Object.entries(dataList?.grids ?? {}).map((grid: any) => {
                    return {
                        id: grid[0],
                        label: grid[1].value,
                        count: grid[1].count
                    }
                }).sort((a: any, b: any) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                })
            }
        ].filter((grid: any) => grid.filters.length > 0),
        specs: Object.entries(dataList?.specifications ?? {})?.map((specification: any) => {
            return {
                id: specification[0],
                category: specification[1]?.description ?? specification[0],
                label: specification[1]?.description ?? specification[0],
                type: specification[1]?.type ?? '',
                filters: Object.entries(specification?.[1]?.values ?? specification?.[1]).map((spec: any) => {
                    return {
                        id: spec[0],
                        parentId: specification[0],
                        label: spec[1].value,
                        count: spec[1].count
                    }
                }).sort((a: any, b: any) => {
                    const floatR = /^[+-]?\d+(\.\d+)?$/;
                    if (floatR.test(a.label) && floatR.test(b.label)) {
                        return parseFloat(a.label) - parseFloat(b.label);
                    }
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                })
            }
        }).filter((spec: any) => spec.filters.length > 0),
        tree: [
            {
                ...dataList?.tree?.[0],
                name: dataList?.tree?.[0]?.description,
                children: [
                    {
                        ...dataList?.tree?.[1],
                        name: dataList?.tree?.[1]?.description,
                        children: []
                    }
                ]
            }
        ]
    };

    return remappedFilters;
}
