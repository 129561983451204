import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import './ProductImagesManager.styles.scss';
import Carousel from './presentational-components/carousel/Carousel';
import ImageMagnifier from './presentational-components/image-magifier/ImageMagnifier';
import { cn } from '@/lib/utils';

const sampleItems: CarouselItem[] = [
    {
        id: '0',
        image: '/images/no_image.svg',
        isSelected: true
    }, {
        id: '1',
        image: '/images/no_image.svg',
        isSelected: false
    }, {
        id: '2',
        image: '/images/no_image.svg',
        isSelected: false
    }, {
        id: '3',
        image: '/images/no_image.svg',
        isSelected: false
    }
];

function ProductImagesManager({ ...props }) {

    const imageMagnifierRef = useRef<HTMLImageElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const [selectedcarouselImage, setSelectedCarouselImage] = useState<string>('');
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [magnifierX, setMagnifierX] = useState(0);
    const [magnifierY, setMagnifierY] = useState(0);
    const [magnifierZoom, setMagnifierZoom] = useState({ scaleX: 0, scaleY: 0 });

    const [zoomFrame, setZoomFrame] = useState({
        display: 'none',
        width: 0,
        height: 0,
        left: 0,
        top: 0,
    });

    useEffect(() => {
        if (!!props?.data?.length) {
            const selectedImage = props?.data?.filter((d: any) => d.isSelected)[0];
            setSelectedCarouselImage(selectedImage.image ?? '/images/no_image.svg');
        }
    }, [props.data])



    function carouselNotifySelection(selection: { image: string }) {
        setSelectedCarouselImage(selection.image ?? '/images/no_image.svg');
    }

    const handleMouseEnter = () => setShowMagnifier(true);
    const handleMouseLeave = () => {
        setShowMagnifier(false);
        setZoomFrame({ ...zoomFrame, display: 'none' })
    };
    const handleMouseMove: MouseEventHandler<HTMLImageElement> = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const image = event.currentTarget;
        const imageRect = image.getBoundingClientRect();

        const magnifierRect: DOMRect | undefined = imageMagnifierRef?.current?.getBoundingClientRect();

        const zoomFrameWidth = imageRect.width / 2;
        const zoomFrameHeight = zoomFrameWidth * ((magnifierRect?.height ?? 1) / (magnifierRect?.width ?? 1));
        const zoomFrameX = (event.clientX - imageRect.left) - (zoomFrameWidth / 2);
        const zoomFrameY = (event.clientY - imageRect.top) - (zoomFrameHeight / 2);
        const magnifierTransform = { scaleX: (imageRect.width / zoomFrameWidth), scaleY: (imageRect.height / zoomFrameHeight) };

        setZoomFrame({
            display: 'block',
            width: zoomFrameWidth,
            height: zoomFrameHeight,
            left: ((zoomFrameX < 0)) ? 0 : ((zoomFrameX > zoomFrameWidth)) ? zoomFrameWidth : zoomFrameX,
            top: ((zoomFrameY < 0)) ? 0 : ((zoomFrameY > (imageRect.height - zoomFrameHeight))) ? (imageRect.height - zoomFrameHeight) : zoomFrameY,
        });

        const x = - (((zoomFrame.left) * (((magnifierRect?.width ?? 1) / zoomFrame?.width) / 2)));
        const y = - (((zoomFrame.top) * (((magnifierRect?.height ?? 1) / zoomFrame.height) / 2)));

        setMagnifierX(x);
        setMagnifierY(y);
        setMagnifierZoom({ scaleX: magnifierTransform.scaleX, scaleY: magnifierTransform.scaleY });
    };

    return (
        <div
            id='product-images-manager'
            className={cn(
                "flex w-full md:flex-row max-md:flex-col",
                /* "!flex-col" */
            )}
        >
            <div
                className={cn(
                    "left-panel flex flex-col relative px-[2.750rem] max-md:w-[100%] max-lg:w-[50%] lg:w-[30%] ",
                    /* "!w-full" */
                )}
            >
                <div className='image-frame p-3 flex-1 w-full h-full max-md:min-h-[50vw]'>
                    <div className='large-image w-full !h-full relative'>
                        <img
                            ref={imageRef}
                            src={selectedcarouselImage ?? '/images/no_image.svg'}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onMouseMove={handleMouseMove}
                            className='w-full h-full absolute object-contain'
                        ></img>
                        <div className='zoom-frame' style={zoomFrame}></div>
                    </div>
                </div>
                <Carousel notifySelection={carouselNotifySelection} items={props?.data ?? sampleItems} mode="FLAT"></Carousel>
            </div>
            <div className="right-panel flex-1 md:w-[100%]">
                <div className='image-magnifier-wrapper' ref={imageMagnifierRef}>
                    <ImageMagnifier
                        magnifiedSrc={selectedcarouselImage}
                        showMagnifier={showMagnifier}
                        magnifierX={magnifierX}
                        magnifierY={magnifierY}
                        magnifierZoom={magnifierZoom}
                        zoomFrame={zoomFrame}
                        imageRef={imageRef}
                    >
                        <div className='flex w-full h-full justify-center items-center'>
                            {props.children}
                        </div>
                    </ImageMagnifier>
                </div>
            </div>
        </div>
    )
}

export default ProductImagesManager;
