'use client';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { BlocksIcon, EyeIcon, InfoIcon, MessageSquareWarningIcon, PuzzleIcon } from 'lucide-react';
import ProductHoverCardTemplate from '../product-hover-card-template';
import ProductHoverCardListTemplate from '../product-hover-card-list-template';
import { useRouter } from 'next/router';
import { cn } from '@/lib/utils';

const ProductActionsBar = ({ ...props }) => {

    const router = useRouter();
    const { locale } = router;

    const row = props.row;
    const data = row.original;
    const actionsColumn = props?.actionColumn ?? null;
    const itemSelectionFn = props.itemSelectionFn ?? (() => { });


    return (
        <div className={cn(
            "h-9 flex items-center justify-between px-3",
            props?.view === 'VERTICAL' ? 'flex-col py-3 h-full w-16' : 'flex-row'
        )}>
            <div className={cn(
                "flex items-center",
                props?.view === 'VERTICAL' ? 'flex-col justify-center space-y-2' : 'flex-row'
            )}>
                {
                    data?.productState &&
                    <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                            <label className={cn(
                                "truncate hover:text-primary cursor-pointer",
                                props?.view === 'VERTICAL' ? 'text-lg' : ''
                            )}>
                                ({data?.productState})
                            </label>
                        </TooltipTrigger>
                        <TooltipContent side="bottom" className="flex items-center gap-4">
                            <label className="">
                                Stato - ({data?.productState})
                            </label>
                        </TooltipContent>
                    </Tooltip>
                }
                {
                    <HoverCard>
                        <HoverCardTrigger asChild>
                            <span className={cn(
                                "cursor-pointer",
                                props?.view === 'VERTICAL' ? 'ml-0' : 'ml-2'
                            )}>
                                <InfoIcon className={cn(
                                    "hover:stroke-primary",
                                    props?.view === 'VERTICAL' ? 'h-6 w-6' : 'h-5 w-5'
                                )} />
                            </span>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-75 border-2">
                            <div>
                                <ul className="text-sm">
                                    <li>
                                        <b>Prezzo di vendita: </b>
                                        <label htmlFor="">{data?.salePrice ? new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format((data?.salePrice)) : '--'}</label>
                                    </li>
                                    {
                                        props.showCost &&
                                        <li>
                                            <b>Prezzo di acquisto senza iva: </b>
                                            <label htmlFor="">{data?.purchasePrice ? new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format((data?.purchasePrice)) : '--'}</label>
                                        </li>
                                    }
                                    {
                                        props.showCost &&
                                        <li>
                                            <b>Prezzo di acquisto con iva: </b>
                                            <label htmlFor="">{data?.purchaseVat ? new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format((data?.purchaseVat)) : '--'}</label>
                                        </li>
                                    }
                                    {
                                        props.showCost &&
                                        <li>
                                            <b>Margine: </b>
                                            <label htmlFor="">{data?.margin ?? '--'}</label>
                                        </li>
                                    }
                                    {
                                        props.showCost &&
                                        <li>
                                            <b>Ricarico: </b>
                                            <label htmlFor="">{data?.markOn ?? '--'}</label>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </HoverCardContent>
                    </HoverCard>
                }
                {
                    data?.exclusive &&
                    <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                            <label className={cn(
                                "truncate hover:text-primary cursor-pointer",
                                props?.view === 'VERTICAL' ? 'ml-0' : 'ml-2'
                            )}>
                                <img
                                    src="/images/exclusive.png"
                                    alt="Authentication"
                                    className={cn(
                                        "block",
                                        props?.view === 'VERTICAL' ? 'h-7 w-7' : 'h-6 w-6'
                                    )}
                                />
                            </label>
                        </TooltipTrigger>
                        <TooltipContent side="bottom" className="flex items-center gap-4">
                            <label className="">
                                Prodotto esclusivo
                            </label>
                        </TooltipContent>
                    </Tooltip>
                }
                {
                    data?.replacedId &&
                    <HoverCard>
                        <HoverCardTrigger asChild>
                            <span className={cn(
                                "cursor-pointer",
                                props?.view === 'VERTICAL' ? 'ml-0' : 'ml-2'
                            )}>
                                <MessageSquareWarningIcon className={cn(
                                    "hover:stroke-primary",
                                    props?.view === 'VERTICAL' ? 'h-6 w-6' : 'h-5 w-5 ml-2'
                                )} />
                            </span>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-75 border-2">
                            <ProductHoverCardTemplate
                                replacedId={data?.replacedId}
                                current={data}
                                tableColumnsDictionary={props.dictionary}
                            ></ProductHoverCardTemplate>
                        </HoverCardContent>
                    </HoverCard>
                }
                {
                    (data?.articleType === 'KIT') &&
                    <HoverCard>
                        <HoverCardTrigger asChild>
                            <span className={cn(
                                "cursor-pointer",
                                props?.view === 'VERTICAL' ? 'ml-0' : 'ml-2'
                            )}>
                                <PuzzleIcon className={cn(
                                    "hover:stroke-primary",
                                    props?.view === 'VERTICAL' ? 'h-6 w-6' : 'h-5 w-5 ml-2'
                                )} />
                            </span>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-75 border-2">
                            <ProductHoverCardListTemplate
                                title={'Prodotti presenti nel Kit'}
                                productId={data?.id}
                                fetchFn={data.fetchKitFn}
                                current={data}
                                tableColumnsDictionary={props.dictionary}
                            ></ProductHoverCardListTemplate>
                        </HoverCardContent>
                    </HoverCard>
                }
                {
                    data?.bundleIds?.length &&
                    <HoverCard>
                        <HoverCardTrigger asChild>
                            <span className={cn(
                                "cursor-pointer",
                                props?.view === 'VERTICAL' ? 'ml-0' : 'ml-2'
                            )}>
                                <BlocksIcon className={cn(
                                    "hover:stroke-primary",
                                    props?.view === 'VERTICAL' ? 'h-6 w-6' : 'h-5 w-5 ml-2'
                                )} />
                            </span>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-75 border-2">
                            <ProductHoverCardListTemplate
                                title={'Prodotti presenti nel Bundle'}
                                ids={data?.bundleIds}
                                current={data}
                                tableColumnsDictionary={props.dictionary}
                            ></ProductHoverCardListTemplate>
                        </HoverCardContent>
                    </HoverCard>
                }
            </div>
            <div className={cn(
                "flex items-center space-x-2",
                props?.view === 'VERTICAL' ? 'flex-col' : 'flex-row'
            )}>
                {
                    actionsColumn?.map((columnTemplate: any, index: number) => {
                        return (
                            <div
                                key={'card-row-' + index}
                                className={cn(
                                    "flex items-center py-2 space-x-2 justify-between hover:stroke-primary",
                                    props?.view === 'VERTICAL' ? 'flex-col' : 'flex-row'
                                )}
                            >
                                {columnTemplate.cell({ row: props.row })}
                            </div>
                        );
                    })
                }
                {
                    (props.mode !== 'FLAT') &&
                    <div className="flex items-center">
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                                <label
                                    className={"cursor-pointer block"}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        itemSelectionFn(row);
                                    }}
                                >
                                    <EyeIcon size={23} className={'hover:stroke-primary stroke-2'} />
                                </label>
                            </TooltipTrigger>
                            <TooltipContent side="bottom" className="flex items-center gap-4">
                                Vai al dettaglio
                            </TooltipContent>
                        </Tooltip>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProductActionsBar;
