'use client';

import { CircleCheckBig, CircleSlash2, ListTodoIcon, Star, StarIcon } from "lucide-react";
import { getCookie } from "cookies-next";
import axiosInterceptorInstance from "../axiosInterceptorInstance";
import { getDataTable, getLIPayload, getTableInstance, getTableRef, rerenderTable, setCF, setDataTable, setDataTableFn, setLIPayload, setTableIsLoading, tableIsLoading } from "@/components/orchestrators/cool-table/components/data-table";
import ProductImagesManager from "@/components/standalone/product-images-manager/ProductImagesManager";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Product } from "@/models/ProductModel";
import { HeartIcon } from "@radix-ui/react-icons";
import { getCurrentUser } from "./profiles.service";
import ProductCard from "@/components/orchestrators/cool-table/components/product-card";
import { getFilterCategoryById } from "./category.service";
import { getSBF, getSGF, getSSF, resetAll } from "@/components/presentational/FilterAccordion";
import { setDS } from "@/components/orchestrators/cool-table/components/data-table-sheet";
import { cn } from "@/lib/utils";
import DataTableTool from "@/components/orchestrators/cool-table/components/shop/data-table-tool";

let sortingStatus: string | null = null;

const getCategoryId = () => null;

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getGlobalProductsTableSettings = () => {
    const storedUserInfo = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('GLOBAL_PRODUCTS_TABLE_SETTINGS') ?? '{}') : null;
    return storedUserInfo;
}

export const setGlobalProductsTableSettings = (settings: any) => {
    (typeof window !== 'undefined') ? localStorage.setItem('GLOBAL_PRODUCTS_TABLE_SETTINGS', JSON.stringify(settings)) : null;
}

export const deleteGlobalProductsTableSettings = (settings: any) => {
    (typeof window !== 'undefined') ? localStorage.removeItem('GLOBAL_PRODUCTS_TABLE_SETTINGS') : null;
}

export const getProductsTableSettingsByCategoryId = (categoryId: string | number) => {
    const storedUserInfo = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('PRODUCTS_TABLE_SETTINGS_ID_' + categoryId) ?? '{}') : null;
    return storedUserInfo;
}

export const setGlobalProductsTableSettingsByCategoryId = (categoryId: string | number, settings: any) => {
    (typeof window !== 'undefined') ? localStorage.setItem('PRODUCTS_TABLE_SETTINGS_ID_' + categoryId, JSON.stringify(settings)) : null;
}

export async function getProductList() {
    await timeout(1000);
    const newSnl = getProducts();
    return newSnl;
}

export async function getProductById(productId: string) {
    await timeout(1000);
    const newSnl = await getProductList();
    const filteredProducts = (newSnl.filter((product: any) => (product.id === productId)));
    return filteredProducts?.[0] ?? null;
}

export async function getCategoryList(categoryId: string = '') {
    await timeout(500);
    const stringifiedProductsCategories = localStorage.getItem('DATA_PRODUCTS_CATEGORIES');
    const productsCategories = JSON.parse(stringifiedProductsCategories ?? '[]');
    return productsCategories;

    /* const ProductListUrl: string = (
        (process.env.NEXT_PUBLIC_PRODUCT_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://local.eleapi/b2b') +
        (process.env.NEXT_PUBLIC_PRODUCT_POST_LIST_SEARCH ?? '/articles/search')
    );
    const result = await axiosInterceptorInstance.post(ProductListUrl, { page: 1, per_page: 100000, image_dim: 100, id_category: categoryId });
    return result.data.result; */
}

export async function getProductsCategoryById(categoryId: string) {
    const productsCategories = await getCategoryList();
    const dataCategory = productsCategories.filter((category: any) => category.id == categoryId);
    return dataCategory[0];

    /* const productsCategories = await getCategoryList(categoryId);
    return productsCategories ?? []; */
}

export const onPreferenceChange = (rowOrigin: Product, currentProducts: Product[]) => {
    const products = currentProducts?.map((product) => {
        if (product.id === rowOrigin.id) {
            product.preferred = !product.preferred;
        }
        return product;
    });
    if (typeof window !== 'undefined') {
        localStorage.setItem('PREFERRED_PRODUCTS', JSON.stringify(products.filter((product) => product.preferred)));
    }
    return products;
}

export const getPreferredProducts = () => {
    const preferredProducts = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('PREFERRED_PRODUCTS') ?? '[]') : null;
    return preferredProducts;
}

export const getCurrentProduct = () => {
    const currentProduct = (typeof window !== 'undefined') ? JSON.parse(getCookie('CURRENT_USER') ?? '') : null;

    const currentProduct2 = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('CURRENT_USER') ?? '') : null;

    return currentProduct ?? currentProduct2;
}

const getQuantity = (product: any) => {
    const stock1334 = (('stock_1334' in product) && (product['stock_1334'] === null)) ? 0 : product['stock_1334'];
    const stock189 = (('stock_189' in product) && (product['stock_189'] === null)) ? 0 : product['stock_189'];
    if ((stock1334 >= 0) || (stock189 >= 0)) {
        return ((parseFloat(stock1334 ?? 0) === 0)) && ((parseFloat(stock189 ?? 0) > 0)) ? parseInt(stock189) : parseInt(stock1334)
    } else {
        return ((parseFloat(product['stock']) === 0)) && ((parseFloat(product['stock']) > 0)) ? parseInt(product['stock']) : parseInt(product['stock'])
    }
}

const getOrder = (product: any) => {
    if (product['ordered_1334'] || product['ordered_189']) {
        return ((parseFloat(product?.['ordered_1134'] ?? 0) === 0)) && ((parseFloat(product['ordered_189']) > 0)) ? parseInt(product['ordered_189']) : parseInt(product['ordered_1334'])
    } else {
        return ((parseFloat(product['ordered']) === 0)) && ((parseFloat(product['ordered']) > 0)) ? parseInt(product['ordered']) : parseInt(product['ordered'])
    }
}

const wherehouseConditionLabelHandler = (product: any) => {
    let result: string = '';
    let contraction: boolean = false;
    const userInfo = getCurrentUser();
    const wherehouseList = userInfo?.stores?.warehouse?.list ?? [];
    const currentWherehouseId = (wherehouseList?.filter((w: any) => (w.id === '189' || w.id === '1334')))?.[0]?.id;
    if (currentWherehouseId === '189') {
        result = (parseFloat(product?.['stock_189']) > 0) ? " magazzino" : '';
    }

    if (currentWherehouseId === '1334') {
        contraction = !(parseFloat(product?.['stock_1334'] && parseFloat(product?.['stock_1986']))) ? true : false;
        result = parseFloat(product?.['stock_1334'] && parseFloat(product?.['stock_1986'])) ? "(" + (parseFloat(product?.['stock_1334'] ?? 0) - parseFloat(product?.['stock_1986'] ?? 0)) + " Pia + " + parseFloat(product?.['stock_1986']) + " Car)" : "Disponibile magazzino";
    }

    return {
        contraction: contraction,
        label: result
    };
}

const wherehouseConditionAvailableHandler = (product: any) => {
    const userInfo = getCurrentUser();
    const wherehouseList = userInfo?.stores?.warehouse?.list ?? [];
    let currentWherehouseId = (wherehouseList?.filter((w: any) => (w.id === '1334')))?.[0]?.id ?? null;
    currentWherehouseId = currentWherehouseId ?? ((wherehouseList?.filter((w: any) => (w.id === '189')))?.[0]?.id ?? null);
    const currentStock = ((('stock_' + currentWherehouseId) in product) && (product?.['stock_' + currentWherehouseId] === null)) ? 0 : parseFloat(product?.['stock_' + currentWherehouseId]);
    const ordered = ((('ordered_' + currentWherehouseId) in product) && (product?.['ordered_' + currentWherehouseId] === null)) ? 0 : parseFloat(product?.['ordered_' + currentWherehouseId]);
    if (((currentStock === 0) || (currentStock === null)) && ((ordered === 0) || (ordered === null))) {
        return "UNAVAILABLE";
    }
    if (((currentStock >= 0) || (ordered >= 0) || product?.['stock'] > 0 || product?.['ordered'])) {
        if (
            (((currentStock === 0)) && ((ordered > 0))) ||
            (((parseFloat(product?.['stock']) === 0)) && ((parseFloat(product?.['ordered']) > 0)))
        ) {
            return "INCOMING";
        } else {
            return "AVAILABLE"
        }
    } else {
        return "UNAVAILABLE"
    }
}

const articleTypeEnumExtractor = (product: any) => {
    const articleTypeList = [
        'FISICO',
        'KIT',
        'BUONO',
        'VIRTUALE',
        'PALLBOX',
        'BUNDLE'
    ];

    let result = null;

    const artycleType = articleTypeList[product?.['id_article_type']] ?? null;

    return artycleType;
}

const productListRemapper = (productList: any[]) => {
    const userInfo = getCookie('CURRENT_USER') ? JSON.parse(getCookie('CURRENT_USER') ?? '') : null;
    const wherehouseList = userInfo?.stores;
    const hiddenPrices = getGlobalProductsTableSettings().hiddenPrice ?? false;
    return productList?.map((product: any) => {
        /* const storageInfos = cookie */
        const newProduct: Product = {
            id: product?.['id_article'],
            title: product?.['d_brand'],
            name: product?.['model'],
            description: product?.['article_description'] ?? product?.['description'],
            productState: product?.['d_grid'],
            exclusive: product?.['exclusive'] ?? false,
            replacedId: null,
            articleType: articleTypeEnumExtractor(product),
            fetchKitFn: () => getKitFetcher(product?.['id_article'], 300),
            bundleIds: null,
            imageLink: (product?.image?.data) ? ('data:image/' + (product?.image?.['data_type'] ?? 'jpg') + ';base64,' + product?.image?.data) : null,
            salePrice: product?.['sale_price'],
            purchasePrice: product?.['purchase_price'],
            purchaseVat: (+product?.['purchase_price'] + ((+product?.['purchase_price'] * +product?.['v_vat']) / 100)),
            margin: product?.['margin'],
            markOn: product?.['mark-on'],
            specs: product?.['specifications']?.filter?.((spec: any) => !!spec?.['value'] && (spec?.['value'] !== 'n.d.')),
            dataSheet: product?.['datasheet'],
            flyerDescription: product?.['flyer_description'],
            categoryTree: product?.['category_tree_erp'],
            barCodes: product?.['bar_codes']?.map((bc: any) => {
                return {
                    vBarCode: bc?.['v_bar_code']
                }
            }),
            prices: [
                {
                    name: 'PUBLIC',
                    /* value: parseFloat(product?.['sale_price']) * (1 + parseFloat(product?.['v_vat']) / 100), */
                    value: parseFloat(product?.['sale_price']),
                    fluctuation: parseFloat(product?.['variation'] ?? '0'),
                    lastUpdated: product?.['variation_date'] ? new Date(parseInt(product?.['variation_date']) * 1000).toISOString() : null,
                    hidden: false
                },
                {
                    name: 'PUBLIC_NO_VAT',
                    value: product?.['purchase_price'],
                    fluctuation: parseFloat(product?.['variation_purchase'] ?? "0"),
                    lastUpdated: product?.['variation_date_purchase'] ? new Date(parseInt(product?.['variation_date_purchase']) * 1000).toISOString() : null,
                    hidden: (getCurrentUser()?.role !== 'LIMITED') ? hiddenPrices : true,
                    raee: (!!product?.['raee']) ? +product?.['raee'] : null,
                },
                /* {
                    name: 'PRIVATE',
                    value: parseFloat(product?.['purchase_price']) * (1 + parseFloat(product?.['purchase_vat']) / 100),
                    fluctuation: parseFloat(product?.['variation_purchase']),
                    lastUpdated: new Date(parseInt(product?.['last_arrival_date'])).toISOString()
                } */
            ],
            storage: [
                {
                    type: "WAREHOUSE",
                    /*main: stock_1334 stock_184 */
                    note: wherehouseConditionLabelHandler(product)?.label,
                    quantity: (getQuantity(product) > 0) ? getQuantity(product) : getOrder(product),
                    value: 0,
                    contraction: wherehouseConditionLabelHandler(product)?.contraction,
                    backOrder: getQuantity(product) > 0 ? product?.['ordered']?.['0'] : product?.['ordered']?.['0'],
                    status: wherehouseConditionAvailableHandler(product),
                    viewCondition: () => true
                },
                {
                    type: "NETWORK",
                    note: null,
                    quantity: product?.['stock_185'] ?? 0,
                    value: 0,
                    backOrder: 0,
                    status: (product?.['stock_185'] && (product?.['stock_185'] > 0)) ? "AVAILABLE" : "UNAVAILABLE",
                    viewCondition: () => {
                        const currentUser = getCurrentUser();
                        const stores = currentUser?.stores;
                        return stores?.network?.list.map((w: any) => w.id)?.length > 0;
                    }
                },
                {
                    type: "TRANSFER",
                    note: null,
                    quantity: null,
                    value: 0,
                    backOrder: 0,
                    status: product?.order_types[2] ? "AVAILABLE" : "UNAVAILABLE",
                    viewCondition: () => true
                },
                {
                    type: "PRIORITY",
                    note: null,
                    quantity: null,
                    value: 0,
                    backOrder: 0,
                    status: product?.order_types[3] ? "AVAILABLE" : "UNAVAILABLE",
                    viewCondition: () => true
                }
            ],
            preferred: false,
            status: null,
            imageList: Object?.entries?.(product?.images ?? {})?.map((obj: any) => obj[1]).map?.((img: any, index: number) => {
                return ({
                    id: img.id,
                    image: (img?.data) ? ('data:image/' + (img.type ?? 'jpg') + ';base64,' + img?.data) : null,
                    dataType: img.dataType,
                    originalHeight: img.original_height,
                    originalWidth: img.original_width,
                    size: img.size,
                    type: img.type,
                    isSelected: (index === 0) ? true : false
                });
            }) ?? null
        }

        return newProduct;
    });
}

export async function fetchDataProducts(payload: any, controller?: any) {
    const ProductListUrl: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/articles/search');

    const response = await axiosInterceptorInstance.post(ProductListUrl, payload, { signal: controller?.signal });

    const rawData = Object.entries(response?.data?.response ?? {});
    const dataList = rawData.map((data: any) => data[1]);
    const data = productListRemapper(dataList);
    const pageCount = Math.ceil(data?.length / (payload?.['per_page'] ?? 10));

    return {
        data: data ?? [],
        pageCount: pageCount,
    };
}

export function dataProductsFetcher(payload: any) {
    const ProductListUrl: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/articles/search');

    return axiosInterceptorInstance.post(ProductListUrl, payload).then((response: any) => {
        const rawData = Object.entries(response?.data?.response ?? {});
        const dataList = rawData.map((data: any) => data[1]);
        const data = productListRemapper(dataList);
        const pageCount = Math.ceil(data?.length / (payload?.['per_page'] ?? 10));

        return {
            data: data ?? [],
            pageCount: pageCount,
        };
    });
}

export const getKitFetcher = async (productId: string, imageSize?: number,) => {
    const params = {
        params: {
            id_article: productId,
            image_dim: imageSize
        }
    };
    const kitUrl: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/articles/kit');
    const result = await axiosInterceptorInstance.get(kitUrl, params);
    let productList: any[] = [];
    if (result?.status === 200) {
        productList = result?.data?.response;
        productList = productListRemapper(productList);
    }
    return productList;
}

export function getDataTableProducts(_payload: any) {

    const payload: any = _payload;
    const userInfo: any = getCurrentUser();

    return async (payload2: any, controller?: any) => {
        const globalTableSettings = getGlobalProductsTableSettings();
        const filtersPayload = globalTableSettings?.filters ?? {};
        const newPayload = {
            sort: JSON.stringify({
                d_brand: 'asc',
                model: 'asc'
            }),
            brands: JSON.stringify(getSBF().map((b: any) => b.id) ?? ''),
            grids: JSON.stringify(getSGF().map((g: any) => g.id) ?? ''),
            specs: JSON.stringify(getSSF().map((s: any) => s.id) ?? ''),
            ...payload2,
            ...filtersPayload,
            ...payload,
            id_addressee: userInfo?.currentDestination?.id ?? null,
        };
        return await fetchDataProducts(newPayload, controller);
    }
}

export function getProducts() {
    const storage = (typeof window !== 'undefined') ? localStorage.getItem('PRODUCTS' + '_' + (getCategoryId() ?? '')) : null;
    const storedProducts = storage ? JSON.parse(storage ?? '[]') : null;
    const products: Product[] = storedProducts ?? [];

    const preferredProducts = getPreferredProducts();
    const preferredProductsIds = preferredProducts ? preferredProducts.map((product: any) => product.id) : [];

    let updatedProducts = products.map((product: Product) => {
        if (preferredProducts && preferredProductsIds.includes(product.id)) {
            product.preferred = true;
        };
        return product;
    })

    return updatedProducts;
}

const setRowStatus = (row: Product, list: Product[], triggerName: string, state: string) => {
    const results = list?.map((product) => {
        if (product.id === row.id) {
            product.status = {
                name: triggerName,
                state: state
            };
        }
        return product;
    })

    return results;
}

export const wishlistChange = async (rowOrigin: any, triggerName: string = '') => {
    const productsState: any = getDataTable();
    const products = productsState.data;

    let productList = setRowStatus(rowOrigin, products, triggerName, 'pending');
    rerenderTable();
    await timeout(1000);
    productList = onPreferenceChange(rowOrigin, productList);

    /* setProducts(productList); */
    productList = setRowStatus(rowOrigin, products, triggerName, 'success');
    rerenderTable();
    await timeout(1000);
    productList = setRowStatus(rowOrigin, products, triggerName, '');
    rerenderTable();
};

export const massivePreferenceChange = (selectedRows: any, state: boolean) => {
    const productsState: any = getDataTable();
    const products = productsState.data;

    let productList = products;

    selectedRows.forEach((row: any) => {
        productList = productList.map((product: any) => {
            if (product?.id === row?.original?.id) {
                product.preferred = state;
            }
            return product;
        });
    });

    if (typeof window !== 'undefined') {
        localStorage.setItem('PREFERRED_PRODUCTS', JSON.stringify(productList.filter((product: any) => product.preferred)));
    }

    rerenderTable();
};

export const massivePreferenceActivationChange = () => {
    const tableInstance: any = getTableInstance();
    const selectedRows = tableInstance.getSelectedRowModel().rows;
    massivePreferenceChange(selectedRows, true);
}

export const massivePreferenceDisactivationChange = () => {
    const tableInstance: any = getTableInstance();
    const selectedRows = tableInstance.getSelectedRowModel().rows;
    massivePreferenceChange(selectedRows, false);
}

const setStorageValues = (product: Product) => {
    const products = getProducts();
    const updatedProducts = products.map((oldProduct) => {
        return (product.id == oldProduct.id) ? product : oldProduct;
    });
    localStorage.setItem('PRODUCTS' + '_' + getCategoryId(), JSON.stringify(updatedProducts));
    rerenderTable();
}

const getStoreListOptions = () => {
    const currentUser = getCurrentUser();
    const stores = currentUser?.stores;

    const optionList = [
        {
            name: "WAREHOUSE",
            label: "Disponibili magazzino centrale",
        },
        {
            name: "NETWORK",
            label: "Disponibili rete vendita",
        },
        {
            name: 'EXCLUSIVE',
            label: 'Articoli esclusivi'
        },
        {
            name: 'SPECS',
            label: 'n.d.'
        }
        /* {
            name: "TRANSFER",
            label: "Disponibili transfer order",
        },
        {
            name: "PRIORITY",
            label: "Disponibili ordine prioritario",
        } */
    ]

    let result: any[] = [];

    optionList.forEach((option) => {
        const storeFilters = stores?.[option.name.toLowerCase()]?.list
        if (stores?.[option.name.toLowerCase()]?.list) {
            if (storeFilters.length > 0) {
                result.push(option);
            }
        };
    });

    result.push(optionList[2]);
    result.push(optionList[3]);

    return result;
}

export const getSortingPricesOptions = () => {
    return [
        {
            name: "PUBLIC-ASC",
            sortingName: 'PUBLIC',
            direction: 'asc',
            label: "Prezzo crescente",
        },
        {
            name: "PUBLIC-DESC",
            sortingName: 'PUBLIC',
            direction: 'desc',
            label: "Prezzo decrescente",
        },
        {
            name: "PRIVATE-ASC",
            sortingName: 'PRIVATE',
            direction: 'asc',
            label: "Costo crescente",
        },
        {
            name: "PRIVATE-DESC",
            sortingName: 'PRIVATE',
            direction: 'desc',
            label: "Costo decrescente",
        },
        {
            name: "SALE-VARIATION-ASC",
            sortingName: 'SALE-VARIATION',
            direction: 'asc',
            label: "Variazione prezzo",
        },
        {
            name: "PURCHASE-VARIATION-ASC",
            sortingName: 'PURCHASE-VARIATION',
            direction: 'asc',
            label: "Variazione costo",
        },
    ]
};

const getDataTableToolToggleButtonTemplate = (props: any) => {
    return <DataTableTool label={props.label} click={props.onClick}></DataTableTool>;
}

let dataTableLength: number = 0;

export function getColumnCfg() {

    return {
        checkboxRow: true,
        cardsTemplate: 'products',
        onChartChange: setStorageValues,
        onDataTable: async (searchPayload: any, router: any, dataTable?: any[], currentPage?: number, controller?: any) => {
            const dataLength = getDataTable()?.data?.length;
            if ((!!getColumnCfg()?.onTableScrollFn && ((dataLength == 0) || (currentPage === 1)))) {
                const payload = {
                    brands: JSON.stringify(getSBF().map((b: any) => b.id) ?? ''),
                    grids: JSON.stringify(getSGF().map((g: any) => g.id) ?? ''),
                    specs: JSON.stringify(getSSF().map((s: any) => s.id) ?? ''),
                    ...searchPayload,
                    id_category: router?.query?.sub || router?.query?.category
                };
                if ((router?.query?.category) || (router?.query?.sub)) {
                    const categoryFilters = await getFilterCategoryById(router?.query?.sub ?? router?.sub, payload, controller);
                    setCF({
                        data: { ...categoryFilters }
                    })
                } else {
                    resetAll();
                }
            }
        },
        resetGlobalProductsTableSettings: () => {
            const payload = {
                stock_stores: null,
                exclusive: null,
                sort: JSON.stringify({ d_brand: 'asc', model: 'asc' })
            };
            const globalTableSettings = getGlobalProductsTableSettings();
            setGlobalProductsTableSettings({ ...globalTableSettings, filters: payload });
            setSortingStatus({ name: null, value: null });
        },
        onFiltersReset: async () => {
            const payload = {
                stock_stores: null,
                exclusive: null,
                sort: JSON.stringify({ d_brand: 'asc', model: 'asc' })
            };
            const globalTableSettings = getGlobalProductsTableSettings();
            setGlobalProductsTableSettings({ ...globalTableSettings, filters: payload });
            setSortingStatus({ name: null, value: null });
            if (!!getColumnCfg()?.onTableScrollFn) {
                setTableIsLoading(true);
                setDataTable({ data: [] });
                setLIPayload({ ...getLIPayload(), ...payload });
            } else {
                setDataTableFn(payload);
            }
        },
        filtersResetShowCondition: () => {
            const globalTableSettings = getGlobalProductsTableSettings();
            const filters = globalTableSettings?.filters;
            let areFiltersSelected = false;
            Object.entries(filters ?? {}).forEach((key) => {
                if (!!key[1] && (key[1] !== '{"d_brand":"asc","model":"asc"}')) {
                    areFiltersSelected = true;
                }
            });

            return areFiltersSelected;
        },
        onTableScrollFn: () => {
            return async (event: any, router?: any) => {
                //await timeout(3000);
                const productList = await getDataTable()?.data;
                const canNextPage = ((productList?.length) >= ((+router?.query?.page * +router?.query?.pageSize)));
                const isBottomClose = (((event.target.scrollHeight - event.target.clientHeight) - event.target.scrollTop) < 100);
                const isLoading = tableIsLoading();
                if (isBottomClose && !isLoading && canNextPage) {
                    dataTableLength = productList?.length;
                    setTableIsLoading(true);
                    const table = getTableInstance();
                    table.nextPage();
                }
            }
        },
        dataTableTools: [
            /* {
                name: 'toggle-price',
                template: () => {
                    const productsTableSettings = getGlobalProductsTableSettings();
                    return getDataTableToolToggleButtonTemplate({
                        label: 'Nascondi i prezzi',
                        onClick: (setState: any) => {
                            setGlobalProductsTableSettings({ ...productsTableSettings, hiddenPrice: !productsTableSettings?.hiddenPrice });
                            let dataTable = getDataTable()?.data;
                            dataTable?.map((product: Product) => {
                                product.prices[0].hidden = !productsTableSettings?.hiddenPrice;
                                return product;
                            });
                            setDataTable({ data: dataTable });
                            setState({
                                label: !productsTableSettings?.hiddenPrice ? 'Mostra i prezzi' : 'Nascondi i prezzi'
                            });
                        },
                    })
                },
            } */
        ],
        massiveActions: [
            {
                name: 'massive-preference-activation',
                className: 'scale-100 stroke-2 fill-secondary-foreground stroke-secondary-foreground hover:fill-primary-foreground',
                iconLeft: ListTodoIcon,
                iconRight: Star,
                onClick: massivePreferenceActivationChange
            },
            {
                name: 'massive-preference-disactivation',
                className: 'scale-100 stroke-2 stroke-secondary-foreground hover:fill-secondary-foreground',
                iconLeft: ListTodoIcon,
                iconRight: Star,
                onClick: massivePreferenceDisactivationChange
            }
        ],
        columns: [
            {
                name: 'name',
                label: 'Name',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: false,
                filterPlaceholder: 'Filtra i profili...',
                icons: [
                    {
                        name: 'preference',
                        label: 'Preferenze',
                        tooltip: true,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active-2 scale-100 stroke-2 fill-primary stroke-primary',
                        classNameConditionKey: 'preferred',
                        icon: StarIcon,
                        onClick: wishlistChange
                    }
                ]
            },
            {
                name: 'storage',
                label: 'Filtro',
                type: 'filter',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                defaultValue: () => {
                    const globalTableSettings = getGlobalProductsTableSettings();
                    const filters = globalTableSettings?.filters ?? {};
                    let defaultValues: any = [];
                    Object.entries(filters).forEach((key) => {
                        if (key[0] === 'stock_stores' && !!key[1]) {
                            defaultValues.push('WAREHOUSE');
                        }
                        if (key[0] === 'exclusive' && !!key[1]) {
                            defaultValues.push('EXCLUSIVE');
                        }
                        if (key[0] === 'no_specs' && (key[1] === true)) {
                            defaultValues.push('SPECS');
                        }
                    });

                    return defaultValues;
                },
                onClick: (someIsSelected: boolean, selectedValues: any) => {
                    const currentUser = getCurrentUser();
                    const stores = currentUser?.stores;
                    let result: any = [];
                    let exclusiveResult: any = false;
                    let noSpecsResults: any = false;

                    selectedValues.forEach((element: string) => {
                        if (element.includes('WAREHOUSE')) {
                            result = [...result, ...stores?.warehouse?.list.map((w: any) => w.id)];
                            const stock_1334 = result.filter((r: any) => r === '1334');
                            const stock_189 = result.filter((r: any) => r === '189');
                            result = stock_1334.length ? ['1334'] : (stock_189?.length ? ['189'] : []);
                        }
                        if (element.includes('NETWORK')) {
                            result = [...result, ...stores?.network?.list.map((w: any) => w.id)];
                        }
                        if (element.includes('EXCLUSIVE')) {
                            exclusiveResult = true;
                        }
                        if (element.includes('SPECS')) {
                            noSpecsResults = true;
                        }
                    });

                    const payload: any = {
                        stock_stores: result.length ? JSON.stringify(result) : null,
                        exclusive: exclusiveResult,
                        no_specs: noSpecsResults
                    }

                    const globalTableSettings = getGlobalProductsTableSettings();
                    setGlobalProductsTableSettings({ ...globalTableSettings, filters: { ...globalTableSettings.filters, ...payload } });

                    if (!!getColumnCfg()?.onTableScrollFn) {
                        setTableIsLoading(true);
                        setDataTable({ data: [] });
                        setLIPayload({ ...getLIPayload(), ...payload });
                    } else {
                        setDataTableFn(payload);
                    }
                },
                onFiltersReset: () => {
                    const payload = {
                        stock_stores: null,
                        exclusive: null
                    };
                    const globalTableSettings = getGlobalProductsTableSettings();
                    setGlobalProductsTableSettings({ ...globalTableSettings, filters: payload });
                    if (!!getColumnCfg()?.onTableScrollFn) {
                        setTableIsLoading(true);
                        setDataTable({ data: [] });
                        setLIPayload({ ...getLIPayload(), ...payload });
                    } else {
                        setDataTableFn(payload);
                    }
                },
                filterFn: (row: any, id: any, value: any) => {
                    /* const rowValue: any = row.getValue(id);
                    if (Array.isArray(rowValue)) {
                        const result = rowValue.some((r: any) => {
                            const condition1 = (r?.status === 'AVAILABLE');
                            const condition2 = (value?.includes(r?.type));
                            const res = (condition1 && condition2);
                            return res;
                        });
                        const column = row._getAllCellsByColumnId(id)?.[id]?.column;
                        console.log('column ', column)
                        return result;
                    }

                    return value.includes(rowValue); */
                },
                options: getStoreListOptions()
            },
            {
                name: 'prices',
                label: 'Ordinamento predefinito',
                type: 'dropdown-filter',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                onClick: (sortingDirection: string | null, directionName: string | null) => {
                    let sort: any = {};
                    switch (directionName) {
                        case 'PUBLIC':
                            sort = { sale_price: sortingDirection };
                            break;
                        case 'PRIVATE':
                            sort = { purchase_price: sortingDirection };
                            break;
                        case 'SALE-VARIATION':
                            sort = { sale_variation: sortingDirection, d_brand: 'asc', model: 'asc' };
                            break;
                        case 'PURCHASE-VARIATION':
                            sort = { purchase_variation: sortingDirection, d_brand: 'asc', model: 'asc' };
                            break;
                        default:
                            sort = { d_brand: 'asc', model: 'asc' }
                            break;
                    }
                    const payload = { sort: JSON.stringify(sort) };
                    const globalTableSettings = getGlobalProductsTableSettings();
                    setGlobalProductsTableSettings({ ...globalTableSettings, filters: payload });
                    const table = getTableInstance();
                    if (!!getColumnCfg()?.onTableScrollFn) {
                        setTableIsLoading(true);
                        setDataTable({ data: [] });
                        setLIPayload({ ...getLIPayload(), ...payload });
                    } else {
                        setDataTableFn(payload);
                    }
                },
                onFiltersReset: () => {
                    const payload = { sort: null };
                    const globalTableSettings = getGlobalProductsTableSettings();
                    setGlobalProductsTableSettings({ ...globalTableSettings, filters: payload });
                    if (!!getColumnCfg()?.onTableScrollFn) {
                        setTableIsLoading(true);
                        setDataTable({ data: [] });
                        setLIPayload({ ...getLIPayload(), ...payload });
                    } else {
                        setDataTableFn(payload);
                    }
                },
                getSortingStatus: () => {
                    const globalTableSettings = getGlobalProductsTableSettings();
                    const filters = JSON.parse(globalTableSettings?.filters?.sort ?? '{}');
                    let defaultValues: any = {};
                    Object.entries(filters).forEach((key) => {
                        switch (key[0]) {
                            case 'sale_price':
                                if (key[1] === 'asc') {
                                    defaultValues = {
                                        name: 'PUBLIC',
                                        value: 'PUBLIC-ASC'
                                    }
                                }
                                if (key[1] === 'desc') {
                                    defaultValues = {
                                        name: 'PUBLIC',
                                        value: 'PUBLIC-DESC'
                                    }
                                }
                                break;
                            case 'purchase_price':
                                if (key[1] === 'asc') {
                                    defaultValues = {
                                        name: 'PRIVATE',
                                        value: 'PRIVATE-ASC'
                                    }
                                }
                                if (key[1] === 'desc') {
                                    defaultValues = {
                                        name: 'PRIVATE',
                                        value: 'PRIVATE-DESC'
                                    }
                                }
                                break;
                            case 'sale_variation':
                                defaultValues = {
                                    name: 'SALE-VARIATION',
                                    value: 'SALE-VARIATION-ASC'
                                }
                            case 'purchase_variation':
                                defaultValues = {
                                    name: 'PURCHASE-VARIATION',
                                    value: 'PURCHASE-VARIATION-ASC'
                                }
                                break;
                            default:
                                defaultValues = null;
                                break;
                        }
                    });

                    const ss = getSortingStatus();
                    /* if (!ss && !!defaultValues) {
                        const table = getTableInstance();
                        const state = table.getState();
                        console.log('tatble state ===> ', state);
                        table.setState({
                            ...state, sorting: [{
                                "id": "prices",
                                "desc": true
                            }]
                        });
                    } */
                    return ss ?? defaultValues;
                },
                setSortingStatus: (sortingData: any) => {
                    const sd = sortingData;
                    setSortingStatus(sd);
                },
                sortingFn: (rowA: any,
                    rowB: any,
                    columnId: any
                ) => {
                    /* const sn: any = getSortingStatus() || 'PUBLIC';
                    const numA = rowA.getValue(columnId).filter((price: any) => price?.name === sn?.name)[0]?.value ?? 0;
                    const numB = rowB.getValue(columnId).filter((price: any) => price?.name === sn?.name)[0]?.value ?? 0;
                    return numA < numB ? -1 : numA > numB ? 1 : 0; */
                },
                options: (() => {
                    const userInfo: any = getCurrentUser();
                    let options = getSortingPricesOptions();
                    if (getGlobalProductsTableSettings()?.hiddenPrice || userInfo?.role === 'LIMITED') {
                        options = options?.filter((option: any) => ((option.sortingName !== 'PRIVATE') && (option.sortingName !== 'PURCHASE-VARIATION')));
                    }
                    return options;
                })()
            },
            /* {
                name: 'actions',
                label: 'Actions',
                type: 'action',
                actions: [
                    {
                        name: 'wishlist',
                        label: 'Wishlist',
                        icon: HeartIcon,
                        className: 'w-5 h-5 stroke-1 ',
                        classNameActive: 'active-2 !fill-red-500 !stroke-red-500',
                        classNameConditionKey: 'preferred',
                        subMenu: false,
                        onClick: wishlistChange
                    }
                ]
            } */
        ]
    }
}

/*************************************************************************************************************************************************************/
/************************************************************************ DETAIL *****************************************************************************/
/*************************************************************************************************************************************************************/

//PREVIEW GALLERY TEMPLATE
const previewGalleryTemplate = (row: any, tableDictionary: any, dataDetails?: any) => {
    console.log('dataDetails', dataDetails?.imageList);
    return (
        <div className="overflow-auto h-full w-full 2xl:max-h-[50vh]">
            <div className="w-full h-full">
                <ProductImagesManager data={dataDetails?.imageList ?? null} view="COLUMN">
                    <div className="flex flex-row flex-1">
                        <div className="w-50 flex-1 p-3 max-lg:hidden" style={{ maxWidth: '16vw' }}>
                            <div className="w-full h-full text-sm">
                                <div defaultValue={'specs'} className="flex flex-col flex-1 w-full h-full">
                                    <div className="overflow-hidden h-full w-full">
                                        <div className="mt-0 w-full h-full relative overflow-hidden">
                                            <div className="w-full h-full overflow-y-auto flex">
                                                <ul className="w-full">
                                                    {
                                                        dataDetails?.flyerDescription?.map((name: string, index: number) => {
                                                            return (
                                                                <li key={index}
                                                                    className={cn(
                                                                        "flex flex-row items-center content-between px-3 py-1",
                                                                        /* { 'bg-gray-100': (index % 2 === 1) } */
                                                                    )}
                                                                >
                                                                    <label className="font-bold" htmlFor="">{name}</label>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-50 flex-1 max-w-[22vw] max-md:max-w-full">
                            <ProductCard className={'h-full'} row={{ ...row, original: dataDetails }} mode="FLAT"></ProductCard>
                        </div>
                    </div>
                </ProductImagesManager>
            </div>
        </div>
    )
};

const iconResolver = (value: string) => {
    switch (value) {
        case 'si':
            return <CircleCheckBig className="w-4 h-4 text-green-500" />
        case 'no':
            return <CircleSlash2 className="w-4 h-4 text-red-500" />
        default:
            return value;
    }
}

//DATA DETAIL TEMPLATE
const dataDetailTemplate = (row: any, tableDictionary: any, dataDetails?: any) => {
    return (
        <div className="p-4 overflow-auto h-full">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4 overflow-auto w-full flex-grow">
                {Object.keys(row?.original).map((key, index) => {
                    return (
                        <div className="mb-2" key={key + '-' + index}>
                            <Label>{tableDictionary?.columns?.[key]?.label ?? key}</Label>
                            <Input value={row?.original[key] ?? ''} disabled={true} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
};

//PREVIEW DETAILS TEMPLATE
const previewDetailsTemplate = (row: any, tableDictionary: any, dataDetails?: any) => {
    return (
        <div className="p-4 overflow-auto h-full">
            {Object.keys(row?.original).map((key, index) => {
                return (
                    <div className="mb-2" key={key + '-' + index}>
                        <Label>{key}</Label>
                        <Input value={row?.original?.[key]?.toString() ?? ''} disabled={true} />
                    </div>
                );
            })}
        </div>
    )
};

const previewDetailsTemplate2 = (row: any, tableDictionary: any, dataDetails?: any) => {
    return (
        <div className="overflow-auto h-full">
            <ul className="w-full" style={{ display: 'table' }}>
                {
                    dataDetails?.specs?.map((spec: any, index: number) => {
                        return (
                            <li key={index}
                                className={cn(
                                    "flex flex-row items-center content-between px-3 py-1",
                                    (index % 2 === 1) ? 'bg-gray-100 dark:bg-secondary' : ''
                                )}
                                style={{ display: 'table-row', padding: '0.5rem' }}
                            >
                                <label className="font-bold" htmlFor="" style={{ display: 'table-cell', width: '50%', padding: '0.5rem 1rem' }}>{spec?.name}:</label>
                                <span className="" style={{ display: 'table-cell', width: '50%', padding: '0.5rem 1rem' }}>
                                    {!!spec?.value ? iconResolver(spec?.value) : '--'}
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
};

const previewDetailsTemplate3 = (row: any, tableDictionary: any, dataDetails?: any) => {
    return (
        <div className="p-4 overflow-auto h-full">
            <ul className="w-full">
                {
                    dataDetails?.dataSheet?.map((name: string, index: number) => {
                        return (
                            <li key={index}
                                className={cn(
                                    "flex flex-row items-center content-between px-3 py-1",
                                    /* { 'bg-gray-100': (index % 2 === 1) } */
                                )}
                            >
                                <label className="font-bold" htmlFor="">{name}</label>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
};

//FETCHER - GET PRODUCT INFO
export const getProductInfoFetcher = async (productId: string, size?: number,) => {
    const userInfo: any = getCurrentUser();
    const productInfoUrl: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/articles/data');
    const result = await axiosInterceptorInstance.get(productInfoUrl, {
        params: {
            id_article: productId,
            image_dim: size,
            id_addressee: userInfo?.currentDestination?.id ?? null
        }
    });
    return result;
}

// PRODUCT INFO REMAPPER
const productInfoRemapper = (rawProductInfo: any) => {
    const productInfo = rawProductInfo?.data?.response;
    const product = productListRemapper([productInfo])?.[0];
    return product;
}

// DETAIL CONFIGURATIONS
export const getProductDetailCfg = (row?: any) => {
    const cfg = {
        disabled: false,
        getDataDetailsFn: async (row: any) => {
            const dataDetail = await getProductInfoFetcher(row.original?.id, 500);
            const remappedDetail = productInfoRemapper(dataDetail);
            return remappedDetail;
        },
        sideFn: (row: any) => {
            return 'right';
        },
        onResize: () => {
            const tableRef = getTableRef();
            const style = {
                maxWidth: '100%',
                width: tableRef?.current?.parentNode?.offsetWidth ? (tableRef?.current?.parentNode?.offsetWidth + 'px') : 'auto',
                height: tableRef?.current?.parentNode?.offsetHeight ? (tableRef?.current?.parentNode?.offsetHeight + 'px') : 'auto',
                position: 'absolute',
                top: tableRef?.current?.parentNode?.offsetTop ? (tableRef?.current?.parentNode?.offsetTop + 'px') : 'auto',
            }
            console.log(style);
            setDS(style);
        },
        main: (row: any, dictionary: any, dataDetails?: any) => previewGalleryTemplate(row, dictionary, dataDetails),
        tabs: [
            {
                name: 'specs',
                label: 'Caratteristiche',
                template: (row: any, dictionary: any, dataDetails?: any) => previewDetailsTemplate2(row, dictionary, dataDetails),
            },
            {
                name: 'datasheet',
                label: 'Scheda',
                template: (row: any, dictionary: any, dataDetails?: any) => previewDetailsTemplate3(row, dictionary, dataDetails),
            }
        ],
        tabDefaultValue: 'specs',
        view: () => {
            return !!cfg?.tabs?.length ? 'COLUMN' : 'ROW';
        }
    }

    return cfg;
}

//GET SORTING STATUS
export const getSortingStatus = () => {
    return sortingStatus;
}

// SET SORTING STATUS
export const setSortingStatus = (sortingData: any) => {
    sortingStatus = sortingData;
}
