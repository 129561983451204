import React, { useRef, useState } from 'react';
import './ImageMagnifier.styles.scss';

const ImageMagnifier = ({ children, magnifiedSrc, showMagnifier, magnifierX, magnifierY, magnifierZoom, zoomFrame, imageRef }: any) => {
    const magnifierRef: any = useRef(null);

    const magnifierRect = magnifierRef.current?.getBoundingClientRect() ?? { width: 0, height: 0 };

    const x = magnifierX;
    const y = magnifierY;

    return (
        <div className="image-magnifier">
            {children}
            {showMagnifier && (
                <div
                    className="magnifier bg-white border-2"
                    ref={magnifierRef}
                >
                    <img
                        src={magnifiedSrc}
                        alt="Magnified view"
                        style={{
                            transform: `scale(${magnifierZoom.scaleX}, ${magnifierZoom.scaleX}) translate(${x}px, ${y}px)`,
                            transformOrigin: 'left top',
                            width: magnifierRect.width + 'px',
                            height: ((imageRef.current.offsetHeight * magnifierRect.width) / imageRef.current.offsetWidth) + 'px',
                            objectFit: 'contain'
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ImageMagnifier;
