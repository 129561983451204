"use client"

import * as React from "react"
import { Button } from "@/components/ui/button"
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger
} from "@/components/ui/sheet"
import { FastForwardIcon, PinIcon, XIcon, } from "lucide-react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import LoadingPanel from "@/components/loading-panel"

export let dataTableSheetModel = { open: false, setOpen: (value: boolean) => { } };

const getSidePreviewTemplate = (row: any, previewCfg: any, tableDictionary: any, dataDetails: any) => {
    if (!row?.original) return <div>no data!</div>;
    return (
        <div
            className={cn(
                "h-full flex flex-col flex-1",
                (previewCfg?.view() === 'COLUMN') ? "" : "items-center justify-center",
                (previewCfg?.main) ? 'overflow-y-auto' : 'overflow-hidden'
            )}
        >
            {
                (previewCfg?.main) ?
                    <div
                        className={cn(
                            "w-full h-full flex flex-col",
                            (previewCfg?.view() === 'COLUMN') ? "" : "h-full",
                        )}
                        style={{ margin: '2.5rem 0' }}
                    >
                        {previewCfg?.main ? previewCfg?.main(row, tableDictionary, dataDetails) : ''}
                    </div>
                    :
                    null
            }
            {
                (previewCfg?.view() === 'COLUMN') &&
                <Tabs defaultValue={previewCfg.tabDefaultValue ?? previewCfg?.tabs?.[0]?.name ?? ''} className="flex flex-col flex-1 bg-white">
                    <TabsList className="grid w-full flex flex-row justify-start p-0 bg-white">
                        {
                            previewCfg?.tabs?.map((tab: any, index: number) => {
                                return (
                                    <TabsTrigger key={tab.name + ' ' + index} className="" value={tab.name}>{tableDictionary?.sheet?.tabs?.[tab.name] ?? tab.label}</TabsTrigger>
                                )
                            })
                        }
                    </TabsList>
                    <div className="overflow-hidden h-full w-full">
                        {
                            previewCfg?.tabs?.map((tab: any, index: number) => {
                                return (
                                    <TabsContent key={tab.name + ' ' + index} className="mt-0 h-full" value={tab.name}>
                                        {tab.template(row, tableDictionary, dataDetails)}
                                    </TabsContent>
                                )
                            })
                        }
                    </div>
                </Tabs>
            }
        </div>
    );
}

export let setDS: any = () => { };
export let setDetailIsOpen: any = () => { };

export let getDetailIsLoading = () => { };
export let setDetailIsLoading = (value: boolean) => { };

export let getTableDataSheet = () => { };
export let setTableDataSheet = (data: any) => { };

export function DataTableSheet({ ...props }: any) {
    const [open, setOpen] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false);
    const [dataDetails, setDataDetails] = React.useState<any>(null);
    const [detailsStyle, setDetailsStyle] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    setDS = setDetailsStyle;
    setDetailIsOpen = setOpen;

    getDetailIsLoading = () => {
        return isLoading;
    }
    setDetailIsLoading = (value: boolean) => {
        return setIsLoading(value);
    };

    getTableDataSheet = () => dataDetails;
    setTableDataSheet = (data: any) => setDataDetails(data);

    const resize = () => {
        console.log(props.previewCfg);
        props.previewCfg?.onResize?.();
    }

    React.useEffect(() => {
        console.log("row data ===> ", !!props.data);
        if (!!Object.keys(props.data).length && props.previewCfg?.getDataDetailsFn) {
            (async () => {
                setIsLoading(true);
                const data = await props.previewCfg?.getDataDetailsFn?.(props.data, 'SHEET');
                /* console.log(data); */
                setDataDetails({ ...data });
                setIsLoading(false);
            })();
        }
    }, [props.data])

    React.useEffect(() => {
        dataTableSheetModel.open = open;
        dataTableSheetModel.setOpen = setOpen;
        window.addEventListener('resize', resize);
        resize();
        setIsLoading(false);
        return () => {
            setDataDetails(null);
            window.removeEventListener('resize', resize);
        }
    }, [])

    const openChange = (state: boolean) => {
        if (state === true) {
            props.onOpenCallback();
            resize();
        } else {
            props.onCloseCallback();
            setDataDetails(null);
        }
        setOpen(state);
    }

    const nullifyEvents = (e: any) => {
        e.preventDefault();
    }

    return (
        <Sheet defaultOpen={false} open={open} onOpenChange={openChange} modal={false}>
            <SheetContent
                id="data-table-sheet-content"
                side={props.side ?? 'top'}
                className={"p-0" + " " + ((props.side === 'right') ? "sm:max-w-[60%] w-[60%]" : "sm:max-w-full w-full h-full") + " " + ((expanded) ? 'sm:max-w-full w-full h-full' : '')}
                onPointerDownOutside={nullifyEvents}
                onInteractOutside={nullifyEvents}
                style={detailsStyle ?? {}}
            >

                {
                    !isLoading ?
                        <div className={cn(
                            "flex flex-col flex-1 h-full transition-all",
                            isLoading ? 'opacity-0' : 'opacity-100'
                        )}>
                            <SheetHeader className="flex flex-col space-y-0">
                                <SheetTitle>
                                    {/* <div className="p-4 space-x-2 border-b flex flex-row items-center">
                                        {
                                            (props.side !== 'top') &&
                                            <div>
                                                {
                                                    (expanded) ?
                                                        <ChevronRight onClick={() => setExpanded(false)} className="cursor-pointer" />
                                                        :
                                                        <ChevronLeft onClick={() => setExpanded(true)} className="cursor-pointer" />
                                                }
                                            </div>
                                        }
                                    </div> */}
                                    <div className="flex flex-row w-full my-2 pl-1 text-xs">
                                        {
                                            dataDetails?.categoryTree?.map?.((c: any, index: number) => {
                                                return (
                                                    <div key={index} className="banner before:bg-primary-foreground">
                                                        <div className="">
                                                            <label htmlFor="">{c.description}</label>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className={cn(
                                            "flex flex-row ml-auto mr-2",
                                            props.layoutDirection === 'vertical' ? '' : ''
                                        )}>
                                            {
                                                props.onPanelAttachCallback ?
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Button
                                                                className=""
                                                                variant="ghost"
                                                                onClick={() => props.onPanelAttachCallback()}
                                                            >
                                                                <PinIcon className="w-5 h-5" />
                                                            </Button>
                                                        </TooltipTrigger>
                                                        <TooltipContent side="bottom" className="flex items-center gap-4">
                                                            Sposta all&apos;interno
                                                        </TooltipContent>
                                                    </Tooltip>
                                                    :
                                                    null
                                            }
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <SheetClose asChild>
                                                        <Button
                                                            className=""
                                                            variant="ghost"
                                                        >
                                                            <FastForwardIcon className="w-5 h-5 origin-center rotate-180" />
                                                            {/* <XIcon className="w-5 h-5" /> */}
                                                        </Button>
                                                    </SheetClose>
                                                </TooltipTrigger>
                                                <TooltipContent side="bottom" className="flex items-center gap-4">
                                                    Chiudi dettaglio
                                                </TooltipContent>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </SheetTitle>
                                <SheetDescription className="m-0 p-0 hidden">
                                    {props.description}
                                </SheetDescription>
                                {/* {
                                    props.actions &&
                                    <div className="w-full p-4">
                                        {props.actions}
                                    </div>
                                } */}
                            </SheetHeader>
                            <div className="grid gap-4 border-t overflow-hidden flex flex-col flex-1">
                                {props.children}
                                {getSidePreviewTemplate(props.data, props.previewCfg, props.tableDictionary, dataDetails)}
                            </div>
                            <SheetFooter>
                                {
                                    props.actions &&
                                    <div className="w-full p-4 border-t">
                                        {props.actions}
                                    </div>
                                }
                                {/* <SheetClose asChild>
                                    <Button type="submit">Save changes</Button>
                                </SheetClose> */}
                            </SheetFooter>
                        </div>
                        :
                        <SheetHeader className={
                            cn(
                                "loader transition-all left-0 flex justify-center items-center w-full h-full",
                                isLoading ? 'opacity-100' : 'opacity-0'
                            )}
                        >
                            <SheetTitle className="hidden"></SheetTitle>
                            <SheetDescription className="m-0 p-0" asChild>
                                <LoadingPanel />
                            </SheetDescription>
                        </SheetHeader>
                }
            </SheetContent>
        </Sheet>
    )
}
