import { useEffect, useState } from "react";
import './Carousel.styles.scss';
import { GenericObject } from "@/models/UtilsModel";
import { ChevronLeft, ChevronRight, CircleIcon } from "lucide-react";
import { cn } from "@/lib/utils";

const Card = (props: GenericObject) => {
    return (
        <li onMouseOver={() => props.onHover(props.currentElement)} className={"card " + ((props.currentElement.isSelected) ? 'selected' : '')}>
            <img src={props.image} alt="" style={{ maxHeight: '3vw' }} />
        </li>
    )
}

const Dot = (props: GenericObject) => {
    return (
        <li onClick={() => props.onHover(props.currentElement)} className={"dot cursor-pointer mx-[0.075rem] " + ((props.currentElement.isSelected) ? 'selected' : '')}>
            <CircleIcon className={cn(
                "fill-primary stroke-primary hover:fill-accent hover:stroke-accent w-5 h-5 dark:fill-secondary dark:stroke-white",
                (props.currentElement.isSelected) ? 'fill-secondary stroke-secondary dark:stroke-white dark:fill-white' : ''
            )}
            ></CircleIcon>
        </li>
    )
}

const Carousel = ({ ...props }) => {
    const [moveClass, setMoveClass] = useState('');
    const [carouselItems, setCarouselItems] = useState<any>(props.items ?? []);

    const mode = props.mode ?? null;

    useEffect(() => {
        onImageHover(props.items[0] ?? []);
    }, [])

    useEffect(() => {
        if (!!props.items?.length) {
            setCarouselItems(props.items ?? []);
        }
    }, [props.items])

    useEffect(() => {
        document.documentElement.style.setProperty('--num', carouselItems.length.toString());
    }, [carouselItems])

    const handleAnimationEnd = () => {
        if (moveClass === 'prev') {
            shiftNext([...carouselItems]);
        } else if (moveClass === 'next') {
            shiftPrev([...carouselItems]);
        }
        setMoveClass('')
    }

    const shiftPrev = (copy: CarouselItem[]) => {
        let lastcard = copy.pop();
        (lastcard) ? copy.splice(0, 0, lastcard) : null;
        setCarouselItems(copy);
    }

    const shiftNext = (copy: CarouselItem[]) => {
        let firstcard = copy.shift();
        (firstcard) ? copy.splice(copy.length, 0, firstcard) : null;
        setCarouselItems(copy);
    }

    const onImageHover = (selectedElement: GenericObject) => {
        props.notifySelection(selectedElement);
        const listWithNewSelection = carouselItems.map((ci: CarouselItem) => {
            ci.isSelected = (ci.id == selectedElement.id) ? true : false;
            return ci;
        })
        setCarouselItems(listWithNewSelection);
    }

    const nextImage = () => {
        //set next image as selected
        const selectedElement = carouselItems.filter((ci: CarouselItem) => ci.isSelected)[0];
        const currentIndex = carouselItems.indexOf(selectedElement);
        const nextIndex = (currentIndex + 1) % carouselItems.length;
        onImageHover(carouselItems[nextIndex]);
    }

    const previousImage = () => {
        //set previous image as selected
        const selectedElement = carouselItems.filter((ci: CarouselItem) => ci.isSelected)[0];
        const currentIndex = carouselItems.indexOf(selectedElement);
        const previousIndex = (currentIndex > 0) ? ((currentIndex - 1) % carouselItems.length) : (carouselItems.length - 1);
        onImageHover(carouselItems[previousIndex]);
    }

    return (
        <div id="carousel">
            <button onClick={(mode !== 'FLAT') ? () => setMoveClass('next') : () => previousImage()} className="prev dark:!bg-transparent dark:!text-white hover:dark:!text-gray-100 focus:!border-0">
                <span className="">
                    <ChevronLeft className="w-8 h-8" />
                </span>
            </button>
            <div className="carouselwrapper module-wrapper dark:bg-transparent">
                <ul onAnimationEnd={handleAnimationEnd} className={`${moveClass} carousel`}>
                    {carouselItems?.map((t: CarouselItem, index: number) => {
                        if (mode === 'FLAT') {
                            return <Dot onHover={onImageHover} key={t.image + index} image={t.image} currentElement={t} />
                        } else {
                            return <Card onHover={onImageHover} key={t.image + index} image={t.image} currentElement={t} />
                        }
                    }
                    )}
                </ul>
            </div>
            <button onClick={(mode !== 'FLAT') ? () => setMoveClass('prev') : () => nextImage()} className="next dark:!bg-transparent dark:!text-white hover:dark:!text-gray-100 focus:!border-0">
                <span className="">
                    <ChevronRight className="w-8 h-8" />
                </span>
            </button>
        </div>

    )
}

export default Carousel;
