'use client';
import React from 'react';
import { Button } from '@/components/ui/button';
import { set } from 'zod';

export let getDTToolsState = () => { };
export let setDTToolsState: any = () => { };

const DataTableTool = (props: any) => {
    const [state, setState] = React.useState({ label: null });

    getDTToolsState = () => {
        return state;
    }

    setDTToolsState = (newState: any) => setState(newState);

    return (
        <Button variant="outline" onClick={() => props.click(setState)}>
            {state?.label ?? props.label}
        </Button>
    )
}

export default DataTableTool;
